import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { baseurl, openTab } from "../../Custom";
import Header from "../nav/Header";
import OpeningHourMain from "../nav/OpeningHourMain";
// import Form from "./Form";
// import OrderDetails from "./OrderDetails";
// import ImageAbout from "./ImageAbout";
import { Form, Formik, Field } from "formik";
import ErrorMsg from "../../includes/inputs/ErrorMsg";
import { newEnquiry } from "../../../actions";
import LoadingBtn from "../../includes/btn/LoadingBtn";
import DatePicker from "react-datepicker";
import "./react-datepicker.css";
import * as Yup from "yup";
class Contact extends Component {
  myTextInput = ({ name, field, placeholder, type }) => {
    return (
      <div className="w-full">
        <div className="text-input">
          <input
            placeholder={placeholder}
            type={type}
            name={name}
            className={`appearance-none   focus:outline-none border-2 bg-gray-200 w-full p-2    `}
            {...field}
          />
        </div>
      </div>
    );
  };

  submitButton = (values, actions) => {
    this.props.newEnquiry(values, actions);
  };
  displayDate = (date) => {
    let ndate = new Date(date);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let month = months[ndate.getMonth()];
    let sdate = ndate.getDate();
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let day = days[ndate.getDay()];

    return `${day}, ${sdate} ${month}`;
  };

  formatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let day = date.getDate();
    //let monthName = months[date.getMonth()];

    return `${year}-${month}-${day}`;
  };
  render() {
    const { isSubmitting } = this.props.commonData;
    const packageform = this.props.getform;
    let selected_date;
    return (
      <div className=" bg-white  ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mr-2 ml-2 md:ml-0 md:mr-10 md:mt-20 grid-contact ">
          <div className=" ml-3 mr-3 md:mr-0 md:ml-10 contactContainer">
            <h1 className="  text-3xl font-bold text-gray-800">Get In Touch</h1>{" "}
            <h1 className="  text-xl">. . . . . .</h1>
            {/* <h1 className="text-xs text-gray-600">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium totam aperiam. Eaque ipsa quae
              ab illo inventore veritatis et quasi architecto beatae vitae dicta
              sunt.
            </h1> */}
            <Formik
              initialValues={{
                name: "",
                date: "",
                no_of_people: "",
                email: "",
                message: "",
                mobile: "",
              }}
              // validate={(values) => {
              //   const errors = {};

              //   if (!values.name) {
              //     errors.name = <div className="error-msg">Enter Name</div>;
              //   }

              //   if (!values.date) {
              //     errors.date = <div className="error-msg">Enter Date</div>;
              //   }
              //   if (!values.no_of_people) {
              //     errors.no_of_people = (
              //       <div className="error-msg">Enter No of People</div>
              //     );
              //   }
              //   if (!values.message) {
              //     errors.message = (
              //       <div className="error-msg">Enter Message Field</div>
              //     );
              //   }

              //   return errors;
              // }}

              onSubmit={(values, actions) => {
                this.submitButton(values, actions);
              }}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                mobile: Yup.string()

                  .label("Mobile")

                  .min(10, "Enter a Valid Mobile Number")
                  .max(10, "Enter a Valid Mobile Number")
                  .required("Required Field"),
                name: Yup.string().required("Required Field"),
                date: Yup.string().required("Required Field"),
                message: Yup.string().required("Required Field"),
                no_of_people: Yup.string().required("Required Field"),
              })}
            >
              {(props: FormikProps<any>) => {
                console.log(props);
                return (
                  <Form>
                    <input className="invisible-input" />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                      <div className="mt-2">
                        <Field
                          placeholder="Name of Event"
                          className="  focus:outline-none border-2 bg-gray-200 w-full p-2 "
                          component={this.myTextInput}
                          name="name"
                          type="text"
                        />
                        {props.touched.name && props.errors.name && (
                          <ErrorMsg msg={props.errors.name} />
                        )}
                      </div>

                      <div>
                        <DatePicker
                          className="  focus:outline-none border-2 bg-gray-200 w-full p-2 mt-2"
                          placeholderText="Select Date"
                          selected={props.values.date}
                          startDate={new Date()}
                          minDate={new Date()}
                          onChange={(date) => {
                            props.setFieldValue("date", date);

                            selected_date = new Date(this.formatDate(date));
                          }}
                          dateFormat="dd-MM-yyyy"
                        />
                        {props.touched.date && props.errors.date && (
                          <ErrorMsg msg={props.errors.date} />
                        )}
                      </div>
                      <div className="mt-0">
                        <Field
                          type="text"
                          component={this.myTextInput}
                          placeholder="Mobile Number"
                          name="mobile"
                          className="   focus:outline-none border-2 bg-gray-200 w-full p-2"
                        />
                        {props.touched.mobile && props.errors.mobile && (
                          <ErrorMsg msg={props.errors.mobile} />
                        )}
                      </div>

                      <div className="mt-0">
                        <Field
                          type="email"
                          component={this.myTextInput}
                          placeholder="Email Address(optional)"
                          name="email"
                          className="  focus:outline-none border-2 bg-gray-200 w-full p-2"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <Field
                        type="number"
                        component={this.myTextInput}
                        placeholder="Expected No Of People"
                        name="no_of_people"
                        className="  focus:outline-none border-2 bg-gray-200 w-full p-2"
                      />
                      {props.touched.no_of_people &&
                        props.errors.no_of_people && (
                          <ErrorMsg msg={props.errors.no_of_people} />
                        )}
                    </div>
                    <div className="mt-4">
                      <Field
                        className="  focus:outline-none border-2 bg-gray-200 w-full p-2  "
                        component={this.myTextInput}
                        name="message"
                        placeholder="Message"
                        type="text"
                      ></Field>

                      {props.touched.message && props.errors.message && (
                        <ErrorMsg msg={props.errors.message} />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="place-order-btn mt-8 font-bold text-xs focus:outline-none
                    "
                    >
                      {packageform ? "Message Send" : `Send Message`}
                    </button>
                    {/* <button
                      type="submit"
                      className="continue-login-btn mb-5  text-center focus:outline-none"
                    >
            {isSubmitting ? <LoadingBtn /> : `Send Message  &#8594`};
                    
                    </button> */}
                  </Form>
                );
              }}
            </Formik>
            {/* <div>
              <input
                placeholder="Phone"
                className=" mt-6  focus:outline-none border-2 bg-gray-200 w-full p-2"
              />
            </div> */}
          </div>

          <OpeningHourMain />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getform: state.enquiryformData.enquiryform,
    commonData: state.commonFunctions,
    selectedDate: state.enquiryformData.selected_date,
  };
};

export default connect(mapStateToProps, { newEnquiry })(Contact);
