import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";

class WhyPeople extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };
  render() {
    return (
      <div className=" whypeaople-container">
        <div className="text-center">
          <h1 className="our-story-text  font-semibold text-xl">
            Why People Choose Us
          </h1>
          <h1 className="text-2xl md:text-3xl font-bold text-gray-800">
            Prepare For First-Class Service
          </h1>
          <h1 className="text-xl font-bold text-gray-800">. . . . . .</h1>
        </div>
        <div className="  gap-8  why-people-container-card m-0 lg:m-16">
          <div id="toss">
            <div id="rev">
              <div class="face">
                <img
                  src={`${baseurl}restabook/8-1.jpg`}
                  // src="restabook/1.jpg"
                  alt="logo"
                  className=" whypeaople-img"
                />
                <div className="whypeople-text text-center">
                  <div className="whypeople-line"></div>
                  <p className="text-2xl font-bold text-white">
                    Daily New Fresh Menus
                  </p>
                  <p className="text-sm text-white font-semibold">
                    START EATING BETTER
                  </p>
                </div>
              </div>
              <div class="face back">
                <div className="back-text p-6 text-center text-white">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                  </p>
                  <p className="mail font-semibold">. . .</p>
                </div>
              </div>
            </div>
          </div>

          <div id="toss">
            <div id="rev">
              <div class="face">
                <img
                  src={`${baseurl}restabook/2-4.jpg`}
                  // src="restabook/2-1.jpg"
                  alt="logo"
                  className=" whypeaople-img"
                />
                <div className="whypeople-text text-center">
                  <div className="whypeople-line"></div>
                  <p className="text-2xl font-bold text-white">
                    Fresh Ingredient, Tasty Meals
                  </p>
                  <p className="text-sm text-white font-semibold">
                    QUALITY IS THE HEART
                  </p>
                </div>
              </div>
              <div class="face back">
                <div className="back-text p-6 text-center text-white">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                  </p>
                  <p className="mail font-semibold">. . .</p>
                </div>
              </div>
            </div>
          </div>

          <div id="toss">
            <div id="rev">
              <div class="face">
                <img
                  src={`${baseurl}restabook/9-1.jpg`}
                  // src="restabook/3-1.jpg"
                  alt="logo"
                  className=" whypeaople-img"
                />
                <div className="whypeople-text text-center">
                  <div className="whypeople-line"></div>
                  <p className="text-2xl font-bold text-white">
                    Creative & Talented Chefs
                  </p>
                  <p className="text-sm text-white font-semibold">
                    HOT & READY TO SERVE
                  </p>
                </div>
              </div>
              <div class="face back">
                <div className="back-text p-6 text-center text-white">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                  </p>
                  <p className="mail font-semibold">. . .</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* mobile view  */}
        <div className="ml-6 mr-6 mobileview-why-people">
          <div class="whypeaople-img1 mt-6 ">
            <img
              src={`${baseurl}restabook/8-1.jpg`}
              // src="restabook/1.jpg"
              alt="logo"
              className=" whypeaople-img1"
            />
            <div className="whypeople-text1 text-center">
              <div className="whypeople-line"></div>
              <p className="text-2xl font-bold text-white">
                Daily New Fresh Menus
              </p>
              <p className="text-sm text-white font-semibold">
                START EATING BETTER
              </p>
            </div>
          </div>
          <div class=" whypeaople-img1 mt-6">
            <img
              src={`${baseurl}restabook/2-4.jpg`}
              // src="restabook/2-1.jpg"
              alt="logo"
              className=" whypeaople-img1"
            />
            <div className="whypeople-text1 text-center">
              <div className="whypeople-line"></div>
              <p className="text-2xl font-bold text-white">
                Fresh Ingredient, Tasty Meals
              </p>
              <p className="text-sm text-white font-semibold">
                QUALITY IS THE HEART
              </p>
            </div>
          </div>
          <div class="whypeaople-img1 mt-6 ">
            <img
              src={`${baseurl}restabook/9-1.jpg`}
              // src="restabook/3-1.jpg"
              alt="logo"
              className=" whypeaople-img1"
            />
            <div className="whypeople-text1 text-center">
              <div className="whypeople-line"></div>
              <p className="text-2xl font-bold text-white">
                Creative & Talented Chefs
              </p>
              <p className="text-sm text-white font-semibold">
                HOT & READY TO SERVE
              </p>
            </div>
          </div>
        </div>
        {/* <div className="text-center">
          <button className="read-text text-center text-sm font-semibold">
            Read More About Us
          </button>
        </div> */}
      </div>
    );
  }
}

export default WhyPeople;
