import React, { Component } from "react"
import { FiChevronRight } from "react-icons/fi"

import { Link, withRouter } from "react-router-dom"
import { baseurl } from "../../Custom"

class Footer extends Component {
    // componentDidUpdate = (prevProps) => {
    //   if (prevProps.user.login !== this.props.user.login) {

    //     window.location.href = `${baseurl}`;
    //   }
    // };
    render() {
        const { bottom } = this.props
        return (
            <div className="footer text-white " name="bottom">
                <img
                    src={`${baseurl}restabook/brush-dec_2.png`}
                    // src="restabook/brush-dec_2.png"
                    alt="logo"
                    className=""
                />
                <div className="footer-container">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <img
                            src={`${baseurl}restabook/20211018_095743_0000-removebg-preview.png`}
                            // src="restabook/logo2.png"
                            alt="logo"
                            className=" h-32 m-auto md:m-0"
                        />
                        <div className="flex gap-4 text-left md:text-right float-left md:float-right self-center">
                            <div className="flex gap-4 m-0 md:m-auto mt-10 md:mt-0">
                                <p className="self-center">Follow us:</p>
                                <div>
                                    <i class="fa fa-facebook-f self-center"></i>

                                    <i class="fa fa-instagram self-center"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-line"></div>
                    <div className="footer-grid gap-4">
                        <div>
                            <h1 className="mail text-xl pb-6">About us</h1>
                            <p className="text-sm text-gray-400">
                                The Backyard Cafe is here to give appetising
                                food on the campus. We are here to provide
                                hygiene and healthy food to the students. The
                                Backyard Cafe is on mobile application and
                                website platform to provide hassle-free service
                                to the students where students can order food
                                from anywhere on the campus.
                            </p>
                        </div>
                        <div className="mr-4">
                            <h1 className="mail text-xl pb-6">Pages</h1>
                            <ul className="text-sm text-gray-400 footer-pages-links">
                                <li>
                                    <Link to="/menu" className="flex gap-2">
                                        <span className="mt-1">
                                            <FiChevronRight />
                                        </span>
                                        Menu
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/terms-conditions"
                                        className="flex gap-2"
                                    >
                                        <span className="mt-1">
                                            <FiChevronRight />
                                        </span>
                                        Terms &amp; Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/privacy-policy"
                                        className="flex gap-2"
                                    >
                                        <span className="mt-1">
                                            <FiChevronRight />
                                        </span>
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/delivery-policy"
                                        className="flex gap-2"
                                    >
                                        <span className="mt-1">
                                            <FiChevronRight />
                                        </span>
                                        Delivery Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h1 className="mail text-xl pb-6">Contact info</h1>
                            <div className="text-sm contact-info-footer">
                                <div className="flex gap-8">
                                    <p>Call :</p> <p>7086602496</p>
                                </div>
                                <div className="flex gap-6">
                                    <p>Write :</p>
                                    <p className="lowercase">
                                        admin@thebackyardcafe.in
                                    </p>
                                </div>
                                <div className="flex gap-4">
                                    <p>Find us :</p> <p>Kahilipara</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer grid  grid-cols-1 md:grid-cols-2 text-xs">
                    <p> &copy;TheBackyardCafe 2022 / All rights reserved.</p>
                    <a
                        href="#top"
                        className="mail mt-4 md:mt-0 md:text-right back-to-top"
                    >
                        <span>Back To Top</span>
                    </a>
                </div>
            </div>
        )
    }
}

export default Footer
