import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";
import NavbarCart from "../cart/NavbarCart";
import Sidemenu from "./Sidemenu";
import { connect } from "react-redux";
import { checkUser, removeToCart, addToCart } from "../../../actions";
import CheckoutLogin from "./Register/CheckoutLogin";
import DesktopLogin from "./Register/DesktopLogin";
import CheckoutLoginMobile from "./Register/CheckoutLoginMobile";
class Header extends Component {
  componentDidMount() {
    this.props.checkUser();
  }
  handleRemove = (id) => {
    //   let total = this.props.total;
    //   this.props.removeToCart(id);
    // };
    let addedItems = this.props.added_item;
    let total = this.props.total;

    const menu = this.props.menumain;
    let products = [];

    const menu_products =
      menu &&
      menu.map((category) => {
        category.products.length > 0 &&
          category.products.map((product) => {
            products.push(product);
          });
      });
    // let addedItem = products.find((item) => item.id == id);
    let grandTotal = 0;
    let existed_item = addedItems.find((item) => id == item.id);
    // console.log(products);
    if (existed_item) {
      let index = addedItems.findIndex(
        (index_product) => index_product.id == existed_item.id
      );
      console.log(index);
      if (addedItems[index].quantity >= 1) {
        addedItems[index].quantity -= 1;
        grandTotal = total - parseInt(existed_item.max_price);
      }
      if (addedItems[index].quantity <= 0) {
        addedItems.splice(index, 1);
      }
      // let grandTotal = total - parseInt(existed_item.max_price);
      this.props.addToCart(addedItems, grandTotal);
    }
  };
  render() {
    function myFunction() {
      document.getElementById("myDropdown").classList.toggle("show");
    }

    // Close the dropdown if the user clicks outside of it
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
    function myFunction2() {
      document.getElementById("myDropdown2").classList.toggle("show");
    }

    // Close the dropdown if the user clicks outside of it
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content1");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show1")) {
            openDropdown.classList.remove("show1");
          }
        }
      }
    };

    const { login, loginLoading, user, isLoggedIn } = this.props.user;
    if (isLoggedIn) {
      window.location.reload();
    }

    let addedItems =
      this.props.items && this.props.items.length > 0 ? (
        this.props.items.map((item) => {
          // let grandTotal = total + parseInt(item.max_price);
          return (
            <div class=" grid grid-cols-3 md:grid-cols-4 gap-2 navbar-cart-border mb-6">
              <div className="text-sm   img-div-remove ">
                <img src={item.image_url} alt="logo" className="   w-full" />
              </div>

              <div className="text-xs    text-gray-800 font-bold col-span-2 self-center">
                {item.product}
                <h1 className="text-gray-600 ">
                  {item.quantity} x Rs {Math.round(item.max_price)}
                </h1>
              </div>

              <div
                onClick={() => {
                  this.handleRemove(item.id);
                }}
                className=" cursor-pointer   text-gray-800 self-center text-right mail"
              >
                x
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center">No Items In Cart</div>
      );
    let total = this.props.total;
    let totalquantity = 0;
    {
      this.props.items &&
        this.props.items.length > 0 &&
        this.props.items.map((item) => {
          totalquantity += parseInt(item.quantity);
        });
    }

    function handleScroll() {
      window.scroll({
        top: 500,
        left: 100,
        behavior: "smooth",
      });
    }

    return (
      <div className="m-10">
        <div className=" header-contact-info flex text-sm font-semibold gap-4 text-white">
          <div>
            <span className="mail">Call now: </span>7086602496
          </div>
          <div>
            <span className="mail"> Write:</span>{" "}
            <span className="lowercase">admin@thebackyardcafe.in</span>
          </div>
        </div>
        <div class="nav  signin-signup  " id="myHeader">
          <div class="  navbar-default  ">
            <ul class="  navbar-nav  grid  grid-cols-3  lg:grid-cols-12">
              <li className="self-center  menu-nav-item">
                <Sidemenu />
              </li>
              <li className="  lg:col-span-6">
                <Link to="/" className="  ">
                  <img
                    src={`${baseurl}restabook/20211018_095743_0000-removebg-preview.png`}
                    alt="logo"
                    className="  backyard-logo"
                  />
                </Link>
              </li>
              <li class="    self-center font-bold text-xs text-right home-nav-item">
                <Link to="/">HOME</Link>
              </li>
              <li className="self-center font-bold text-xs text-right home-nav-item">
                <Link to="/menu">MENU</Link>
              </li>
              <li className="self-center font-bold text-xs text-right home-nav-item">
                {/* <a onClick={() => handleScroll()}>ABOUT</a> */}
                <Link to="/" onClick={() => handleScroll()}>
                  ABOUT
                </Link>
              </li>
              <li className="self-center font-bold text-xs text-right home-nav-item">
                <Link to="/enquiry">ENQUIRY</Link>
              </li>
              <li className="self-center font-bold text-xs text-right home-nav-item">
                {!loginLoading && !login ? (
                  <DesktopLogin />
                ) : !loginLoading && login ? (
                  <div className=" dropdown">
                    <div className="dropbtn  " onClick={() => myFunction2()}>
                      MY ACCOUNT
                    </div>
                    <div className="dropdown-content1" id="myDropdown2">
                      <Link
                        to="/my-orders"
                        className="lg:p-4 py-3 px-0 block lg:mb-0 mb-2 text-xs"
                      >
                        ORDERS
                      </Link>
                      <Link
                        to="/logout"
                        className="lg:p-4 py-3 px-0 block lg:mb-0 mb-2 text-xs"
                      >
                        LOGOUT{" "}
                      </Link>
                    </div>
                  </div>
                ) : null}
              </li>

              <li className="self-center dropdown">
                <Link to="#" className=" ">
                  <div
                    className="text-right float-right  notification"
                    onClick={() => myFunction()}
                  >
                    <img
                      // src="restabook/shopping-bag.svg"
                      src={`${baseurl}restabook/shopping-cart.png`}
                      alt="logo"
                      className="h-8    dropbtn  card-icon "
                    />
                    {/* <span class="badge">
                      {item.totalquantity} */}
                    {/* {this.props.items.map((item, index) => ( */}
                    <span class="badge ">{totalquantity}</span>
                    {/* ))} */}
                  </div>
                </Link>
              </li>
            </ul>
          </div>

          <div id="myDropdown" class="dropdown-content">
            <div className="grid grid-cols-2 mb-4  ">
              <h1 className="text-xl mail font-semibold">My Cart</h1>
              <p className="text-xs font-semibold self-center text-right">
                {addedItems && addedItems.length ? addedItems.length : 0} items
              </p>
            </div>
            <div className="scrollmenu10">{addedItems}</div>
            <div className="grid grid-cols-2 mt-4">
              <h1 className="    font-semibold">Subtotal:</h1>
              <h1 className="text-xs font-semibold self-center text-right">
                Rs {total}
              </h1>
            </div>
            <div className="grid grid-cols-2  gap-4 mt-4">
              <Link to="/cart" className=" ">
                <p className="view-cart-btn text-xs  text-center">View Cart</p>
              </Link>
              {!loginLoading && !login ? (
                <div>
                  <CheckoutLogin />
                  <CheckoutLoginMobile />
                </div>
              ) : !loginLoading && login ? (
                <Link to="/checkout" className=" text-right">
                  <div className=" text-right">
                    <p className="view-cart-btn text-xs text-center m-auto">
                      Checkout
                    </p>
                  </div>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.registerData,
    items: state.cartData.addedItems,
    total: state.cartData.total,
    totalquantity: state.cartData.totalquantity,
    menumain: state.homepageData.menu,
    added_item: state.cartData.addedItems,
  };
};

export default withRouter(
  connect(mapStateToProps, { checkUser, removeToCart, addToCart })(Header)
);
