import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";
import Header from "../nav/Header";
import Slider from "react-slick";
import { LeftArrow, RightArrow } from "../../CustomArrows";
import ImageAbout from "./ImageAbout";
class HeaderAbout extends Component {
  render() {
    var settings = {
      infinite: true,

      slidesToShow: 1,
      speed: 500,

      nextArrow: <LeftArrow />,
      prevArrow: <RightArrow />,

      responsive: [
        {
          breakpoint: 620,
          settings: {
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            dots: true,
            infinite: true,
            centerPadding: "20px",
            centerMode: true,
          },
        },
      ],
    };
    return (
      <div class="hero-image-services ">
        <img
          src={`${baseurl}restabook/10.jpg`}
          // src="restabook/10.jpg"
          alt="logo"
          className="hero-image-services image"
        />
        <div className="m-10">
          <Header />
          <div className="about-homepage-div text-center ">
            <h1 className="our-story-text text-xl pb-4 ">
              Enjoy your time in our restaurant with pleasure.
            </h1>
            <h1 className="font-bold text-4xl text-white homepage-heading">
              About our Restaurant
            </h1>
            <div className="font-bold text-xl mail pb-6 ">. . . . .</div>
          </div>
        </div>
        <div className="brush-img-about">
          <img
            src={`${baseurl}restabook/brush-dec.png`}
            // src="restabook/brush-dec.png"
            alt="logo"
            className=" "
          />
          <div className="blank-space bg-white "></div>
        </div>
      </div>
    );
  }
}

export default HeaderAbout;
