import React from "react";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";

export const LeftArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow prev-arrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="carousel-nav-btn">
        <FaCaretRight />
      </div>
    </div>
  );
};

export const RightArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow next-arrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="carousel-nav-btn">
        <FaCaretLeft />
      </div>
    </div>
  );
};
