import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl, baseurl2 } from "../../Custom";
import { connect } from "react-redux";
import { fetchGallery } from "../../../actions";
import Slider from "react-slick";
import { LeftArrow, RightArrow } from "../../CustomArrows";
import ImageSlider from "./ImageSlider";
class Chef extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };
  componentDidMount() {
    this.props.fetchGallery();
  }
  render() {
    const galleries = this.props.galleries;
    const image =
      galleries && galleries.length > 0
        ? galleries.map((gallery_item) => {
            return (
              // <div className="shadow-lg" key={gallery_item.id}>
              // <a href={`${baseurl2}/${gallery_item.image}`}>
              <ImageSlider image={`${baseurl2}/${gallery_item.image}`} />
            );
          })
        : null;
    const image1 =
      galleries && galleries.length > 0
        ? galleries.map((gallery_item) => {
            return (
              // <div className="shadow-lg" key={gallery_item.id}>
              // <a href={`${baseurl2}/${gallery_item.image}`}>
              <img
                src={`${baseurl2}/${gallery_item.image}`}
                alt="logo"
                className="   shadow-lg gallery-homepage"

                // onClick={() => show1()}
              />
              // </a>

              // </div>
              //  <Categoriesitems
              //   title={categories_cake.name}
              //   key={categories_cake.id}
              //   title1={categories_cake.name}
              //   image={`${baseurl2}${categories_cake.api_image}`}
              //   newTo={categories_cake.slug}
              // />
            );
          })
        : null;
    var settings = {
      infinite: true,

      slidesToShow: 2,
      speed: 500,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: true,
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 2,
            autoplay: false,

            infinite: true,
          },
        },
        {
          breakpoint: 620,
          settings: {
            dots: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,

            infinite: true,
          },
        },
      ],
    };

    return (
      <div className=" mt-12 md:mt-32  text-center">
        {/* <p className="our-story-text">Our awesome team</p> */}
        <p className=" text-gray-700 font-semibold text-3xl">Gallery</p>
        <p className="  ">. . . . . .</p>

        <div className="  mt-10 ml-0 mr-0 lg:ml-16 lg:mr-16   ">
          <div class="grid grid-cols-1 md:grid-cols-4  gap-0 non-slider-gallery-section">
            {image}
          </div>
          <div className="slider-gallery-section">
            {" "}
            <Slider {...settings}>{image1}</Slider>
          </div>

          {/* <div className="shadow-lg">
            <img
              src={`${baseurl}restabook/1-1.jpg`}
               
              alt="logo"
              className=" "
            />
            <div className="p-6">
              <h1 className="text-xl font-semibold">Kevin Gray</h1>
              <p className="mail">Master chef in New York</p>
              <p className="text-gray-700 text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p className="mail">. . .</p>
            </div>
          </div>
          <div className="shadow-lg">
            <img
              src={`${baseurl}restabook/2-2.jpg`}
             
              alt="logo"
              className=" "
            />
            <div className="p-6">
              <h1 className="text-xl font-semibold">Austin Evon</h1>
              <p className="mail">Master chef in Florida</p>
              <p className="text-gray-700 text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p className="mail">. . .</p>
            </div>
          </div>
          <div className="shadow-lg">
            <img
              src={`${baseurl}restabook/3-2.jpg`}
             
              alt="logo"
              className=" "
            />

            <div className="p-6">
              <h1 className="text-xl font-semibold">Taylor Roberts</h1>
              <p className="mail">Master chef in Maiami</p>
              <p className="text-gray-700 text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p className="mail">. . .</p>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    galleries: state.homepageData.gallery,
  };
};
export default connect(mapStateToProps, { fetchGallery })(Chef);
