import React, { Component } from "react";
import { Dialog } from "@react-md/dialog";
import { Link } from "react-router-dom";
import { useToggle } from "@react-md/utils";
import CartItems from "./CartItems";


const ViewCart = () => {
  const [visible, enable, disable] = useToggle(false);

  return (
    <>
        <button onClick={enable} className=" bg-gray-800 text-white text-lg px-2 py-1">View Cart</button>
        <Dialog
            id="simple-dialog"
            visible={visible}
            aria-labelledby="dialog-title"
            className="pb-10 popup-login place-order-page"
        >
            <span className="close3" id="dialog-close" onClick={disable}>
            &times;
            </span>
            <CartItems />
        </Dialog>
    </>
  );
};

export default ViewCart;
