import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl } from "../../Custom";
class Blogitems extends Component {
  render() {
    const { item, note, image } = this.props;
    return (
      <div className=" mt-32 md:mt-20  ">
        <h1 className="text-xl font-semibold pb-4">{item}</h1>

        <img
          // src={`${baseurl}restabook/10.jpg`}
          src={`${baseurl}${image}`}
          alt="logo"
          className=" blog-image   "
        />

        <div className="    ">
          <p className="text-gray-700 text-sm pt-4   ">{note}</p>
        </div>
      </div>
    );
  }
}

export default Blogitems;
