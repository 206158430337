import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";
import OpeningHourMain from "./OpeningHourMain";

class OpeningHours extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };
  render() {
    return (
      <div className=" openinghours-container">
        <div class="grid-opening ">
          <div className="flower-img1">
            <img
              src={`${baseurl}restabook/12.jpg`}
              // src="restabook/12.jpg"
              alt="logo"
              className=" flower-img1"
            />
            <div className=" about-homepage-div text-white text-center">
              <p className=" note-opening">
                "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                quae ab illo inventore veritatis et quasi accusantium."
              </p>
              <div className="grid grid-cols-2 mt-2 m-auto">
                <p className="self-center font-semibold">
                  Kevin Kowalsky - Restaurant’s cheaf
                </p>

                <img
                  src={`${baseurl}restabook/signature.png`}
                  // src="restabook/signature.png"
                  alt="logo"
                  className="  "
                />
              </div>
            </div>
          </div>
          <OpeningHourMain />
          {/* <div className="flower-img ">
            <img
              // src={`${baseurl}restabook/10.jpg`}
              src="restabook/7.png"
              alt="logo"
              className="flower-img "
            />
            <div className="opening-hour-right-side-div  ">
              <h1 className="our-story-text text-center text-2xl ">
                Call For Reservations
              </h1>
              <h1 className="text-center font-bold text-2xl text-white pt-2">
                {" "}
                Opening Hours
              </h1>
              <h1 className="mail text-center font-bold ">. . . . . .</h1>
              <div className="grid grid-cols-3 gap-6">
                <div>
                  <h1 className="mail text-center">Sunday to Tuesday</h1>
                  <h1 className="text-white font-bold text-2xl text-center">
                    09:00
                  </h1>
                  <h1 className="text-white font-bold text-2xl text-center">
                    22:00
                  </h1>
                </div>
                <div></div>
                <div>
                  <h1 className="mail text-center">Friday to Saturday</h1>
                  <h1 className="text-white font-bold text-2xl text-center">
                    11:00
                  </h1>
                  <h1 className="text-white font-bold text-2xl text-center">
                    19.00
                  </h1>
                </div>
                <div className="line border"></div>
                <div className="text-white  text-center font-bold">.</div>
                <div className="line border"></div>
              </div>
              <div className=" mt-6 ">
                <div className="number-text font-bold text-white text-2xl p-4 text-center ">
                  +7(111)123456789
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default OpeningHours;
