import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab } from "../../Custom";

class HappyCustomer extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };

  render() {
    return (
      <div className="happycustomer-container   ">
        <div className="happycustomer-img2">
          <img
            src={`${baseurl}restabook/brush-dec_2.png`}
            // src="restabook/brush-dec_2.png"
            alt="logo"
            className="happycustomer-img2"
          />
        </div>
        <div className="happy-customer-img">
          {" "}
          <img
            src={`${baseurl}restabook/14s.jpg`}
            // src="restabook/14s.jpg"
            alt="logo"
            className="happy-customer-img happy-img"
          />
          <div
            className="grid grid-cols-2
          lg:grid-cols-4 total-members"
          >
            <div className="text-center">
              <h1 className="our-story-text font-bold text-4xl">254</h1>
              <h1 className="text-white font-bold text-sm">
                New Visitors Every Week
              </h1>
              <h1 className="mail font-semibold text-xl">. . .</h1>
            </div>
            <div className="text-center">
              <h1 className="our-story-text font-bold text-4xl">12168</h1>
              <h1 className="text-white font-bold text-sm">
                Happy Customers Every Year
              </h1>
              <h1 className="mail font-semibold text-xl">. . .</h1>
            </div>
            <div className="text-center">
              <h1 className="our-story-text font-bold text-4xl">172</h1>
              <h1 className="text-white font-bold text-sm">Won Awards</h1>
              <h1 className="mail font-semibold text-xl">. . .</h1>
            </div>
            <div className="text-center">
              <h1 className="our-story-text font-bold text-4xl">732</h1>
              <h1 className="text-white font-bold text-sm">
                Weekly Deliveries
              </h1>
              <h1 className="mail font-semibold text-xl">. . .</h1>
            </div>
          </div>
        </div>
        <div className="happycustomer-img">
          <img
            src={`${baseurl}restabook/brush-dec.png`}
            // src="restabook/brush-dec.png"
            alt="logo"
            className="happycustomer-img"
          />
        </div>
      </div>
    );
  }
}

export default HappyCustomer;
