import React, { Component } from "react"
import OrderItem from "./OrderItem"
import { connect } from "react-redux"
import { Howl } from "howler"
import { FaSearch } from "react-icons/fa"

import {
    lastOrderID,
    employeeOrders,
    storeSearchTerm,
    storeOrders,
} from "../../../actions"
import { baseurl } from "../../Custom"

class Orders extends Component {
    componentDidMount() {
        this.props.employeeOrders()
        this.updateOrders("no")
    }

    updateOrders = (close) => {
        let timer
        if (close == "no") {
            timer = setInterval(() => {
                this.props.employeeOrders()
            }, 5000)
        }

        if (close == "yes") {
            clearInterval(timer)
        }
    }

    componentDidUpdate(prevProps) {
        const latestOrder =
            this.props.orders.length > 0 ? this.props.orders[0].id : 0

        if (parseInt(this.getCookie("lastOrderID")) !== latestOrder) {
            this.props.lastOrderID(latestOrder)
            this.playSound()
        }
    }

    componentWillUnmount() {
        this.updateOrders("yes")
    }

    getCookie = (cname) => {
        var name = cname + "="
        var decodedCookie = decodeURIComponent(document.cookie)
        var ca = decodedCookie.split(";")
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) === " ") {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ""
    }

    searchOrders = (ev) => {
        let term = ev.target.value
        this.props.storeSearchTerm(term, "EMPLOYEE_ORDERS_SEARCH")

        if (term !== "") {
            const filteredOrders = this.props.orders.filter((order) => {
                return order.invoice_no.toString().includes(term)
            })

            this.props.storeOrders(filteredOrders)
        } else {
            this.props.storeOrders(this.props.employeeData.full_orders)
        }
    }

    playSound = () => {
        // const audio = new Audio(`${baseurl}restabook/notification.mp3`);
        // audio.play();
        const sound = new Howl({
            src: [`${baseurl}restabook/notification-new.mp3`],
            volume: 1.0,
            onend: function () {
                //
            },
        })
        sound.play()
    }

    render() {
        const { orders } = this.props

        const { ordersSearch, full_orders } = this.props.employeeData

        return (
            <>
                <div id="first" className="active content-wrapper p-4">
                    <div className="flex justify-end">
                        <div className="border border-gray-400 px-4 mb-5">
                            <div className="flex w-full">
                                <div className="mt-3">
                                    <FaSearch />
                                </div>
                                <input
                                    type="text"
                                    className="p-2 w-full text-black text-base border-gray-400 focus:outline-none"
                                    placeholder="Search Orders..."
                                    value={ordersSearch}
                                    onChange={(ev) => this.searchOrders(ev)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                        {orders && orders.length > 0 ? (
                            orders.map((order, index) => (
                                <OrderItem
                                    key={`order-item-${index}`}
                                    order_id={order.id}
                                    invoice_no={order.invoice_no}
                                    time={order.created_at}
                                    customer={order.customer_name}
                                    location={order.business_location}
                                    status={order.res_order_status}
                                    is_printed={order.is_printed}
                                    delivery_time={order.custom_field_4}
                                />
                            ))
                        ) : (
                            <div className="flex justify-center xl:col-span-4 md:col-span-3 sm:col-span-2 col-span-1">
                                <div>
                                    <div className="flex justify-center">
                                        <img
                                            src={`${baseurl}restabook/frying-pan.png`}
                                            alt="frying pan"
                                            className="w-1/2"
                                        />
                                    </div>
                                    <h1 className="font-bold text-3xl mt-4">
                                        No orders available
                                    </h1>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div id="receipt_section"></div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        employeeData: state.employeeData,
    }
}

export default connect(mapStateToProps, {
    lastOrderID,
    employeeOrders,
    storeSearchTerm,
    storeOrders,
})(Orders)
