import React from "react"
import { connect } from "react-redux"
import { BsXCircle } from "react-icons/bs"

import "./custom-modal.css"
import { hideModal } from "../../../actions"

class Modal extends React.Component {
    render() {
        return (
            <div className="modal-overlay">
                <div className="modal-wrapper">
                    <div className="modal-body">
                        <button
                            type="button"
                            onClick={() => this.props.hideModal()}
                            className="modal-close-btn"
                            title="Close"
                        >
                            <BsXCircle />
                        </button>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        commonData: state.commonFunctions,
    }
}

export default connect(mapStateToProps, { hideModal })(Modal)
