import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab } from "../../Custom";

// import ImageAbout from "./ImageAbout";
class Orderitems extends Component {
  render() {
    const { item, price } = this.props;
    return (
      <div className="grid grid-cols-3 mt-6 ">
        <div className="col-span-2">
          <h1 className="  font-semibold">{item}</h1>
          <div className="line-cart  pt-4"></div>
        </div>
        <h1 className="mail  text-right text-xs  "> Rs {price}</h1>
      </div>
    );
  }
}

export default Orderitems;
