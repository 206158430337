import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";

class Explore extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };
  render() {
    return (
      <div className=" explore-container ">
        <div className="grid grid-cols-1 md:grid-cols-5 ">
          <div className="flex md:col-span-2">
            <img
              src={`${baseurl}restabook/wave2.png`}
              // src="restabook/wave2.png"
              alt="logo"
              className="wave-img"
            />
            <div className="p-6 explore-text">
              <h1 className="our-story-text font-bold">Our Story</h1>
              <h1 className="font-bold text-4xl   few-words-txt">
                Few words about us
              </h1>
              <h1 className="font-bold text-2xl pb-6">. . . . . .</h1>
              <p>
                The Backyard Cafe is here to give appetising food on the campus.
                We are here to provide hygiene and healthy food to the students.
                The Backyard Cafe is on mobile application and website platform
                to provide hassle-free service to the students where students
                can order food from anywhere on the campus.
              </p>
              <Link to="/menu">
                <button className="text-white text-sm font-bold pl-10 pr-10 pt-4 pb-4 explore-btn   ">
                  Explore Our Menu
                </button>
              </Link>
            </div>
          </div>
          <img
            src={`${baseurl}restabook/cube.png`}
            // src="restabook/cube.png"
            alt="logo"
            className=" cube-image"
          />
          <div className="md:col-span-2  ">
            <img
              src={`${baseurl}restabook/8.jpg`}
              // src="restabook/8.jpg"
              alt="logo"
              className=" story-img"
            />
            <img
              src={`${baseurl}restabook/3.jpg`}
              // src="restabook/3.jpg"
              alt="logo"
              className="our-story-img "
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Explore;
