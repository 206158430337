import React, { Component } from "react"
import { connect } from "react-redux"
import { TiDeleteOutline, TiThSmall } from "react-icons/ti"
import { FaSearch } from "react-icons/fa"

import {
    addToCart,
    placeOrder,
    employeePlaceOrder,
    storeSearchTerm,
    storeMenu,
    retakeOrders,
    fetchCategories,
} from "../../../actions"
import { baseurl } from "../../Custom"
import ViewCart from "./ViewCart"

class PlaceOrder extends Component {
    componentDidUpdate(prevProps) {
        //console.log(this.props.full_menu)
    }

    IncrementItem = (id) => {
        // this.props.addToCart(id);
        let addedItems = this.props.added_items
        let total = this.props.total

        const menu = this.props.menumain
        let products = []

        menu &&
            menu.map((category) => {
                category.products.length > 0 &&
                    category.products.map((product) => {
                        products.push(product)
                    })
            })

        let addedItem = products.find((item) => item.id == id)
        let existed_item = addedItems.find((item) => id == item.id)
        // console.log(products);
        if (existed_item) {
            let index = addedItems.findIndex(
                (index_product) => index_product.id == addedItem.id
            )
            console.log(index)
            addedItems[index].quantity += 1
        } else {
            addedItem.quantity = 1
            addedItems = [...addedItems, addedItem]
        }
        const grandTotal = total + parseInt(addedItem.max_price)
        this.props.addToCart(addedItems, grandTotal)
    }

    DecreaseItem = (id) => {
        // this.props.addToCart(id);
        let addedItems = this.props.added_items
        let total = this.props.total

        const menu = this.props.menumain
        let products = []

        menu &&
            menu.map((category) => {
                category.products.length > 0 &&
                    category.products.map((product) => {
                        products.push(product)
                    })
            })
        // let addedItem = products.find((item) => item.id == id);
        let grandTotal = 0
        let existed_item = addedItems.find((item) => id == item.id)
        // console.log(products);
        if (existed_item) {
            let index = addedItems.findIndex(
                (index_product) => index_product.id == existed_item.id
            )
            console.log(index)
            if (addedItems[index].quantity >= 1) {
                addedItems[index].quantity -= 1
                grandTotal = total - parseInt(existed_item.max_price)
            }
            if (addedItems[index].quantity <= 0) {
                addedItems.splice(index, 1)
            }
            // let grandTotal = total - parseInt(existed_item.max_price);
            this.props.addToCart(addedItems, grandTotal)
        }
    }

    handleRemove = (id) => {
        // this.props.removeToCart(id);
        let addedItems = this.props.added_items
        let total = this.props.total

        const menu = this.props.menumain
        let products = []

        const menu_products =
            menu &&
            menu.map((category) => {
                category.products.length > 0 &&
                    category.products.map((product) => {
                        products.push(product)
                    })
            })
        // let addedItem = products.find((item) => item.id == id);
        let grandTotal = 0
        let existed_item = addedItems.find((item) => id == item.id)
        // console.log(products);
        if (existed_item) {
            let index = addedItems.findIndex(
                (index_product) => index_product.id == existed_item.id
            )

            //   if (addedItems[index].quantity >= 1) {
            //     addedItems[index].quantity -= 1;
            //     grandTotal = total - parseInt(existed_item.max_price);
            //   }
            //   if (addedItems[index].quantity <= 0) {
            addedItems.splice(index, 1)
            //}
            grandTotal =
                total - parseInt(existed_item.max_price) * existed_item.quantity
            this.props.addToCart(addedItems, grandTotal)
        }
    }

    confirmOrder = () => {
        let products = this.props.added_items
        let grandTotal = this.props.total
        let contact_id = 1

        this.props.employeePlaceOrder(
            products,
            contact_id,
            grandTotal,

            "",
            ""
        )
    }

    takeNewOrder = () => {
        this.props.retakeOrders(this.props.full_menu)
        this.props.storeMenu(this.props.full_menu, "RESTORE_MENU")
        this.props.fetchCategories()
    }

    searchMenu = (ev) => {
        let term = ev.target.value

        this.props.storeSearchTerm(term, "EMPLOYEE_PLACE_ORDER_SEARCH")

        if (term.length > 3) {
            const filteredMenu = []

            this.props.menumain.map((category) => {
                let filteredProducts =
                    category.products.length > 0 &&
                    category.products.filter((prod) => {
                        return prod.product
                            .toLowerCase()
                            .includes(term.toLowerCase())
                    })

                if (filteredProducts && filteredProducts.length > 0) {
                    let menuCat = category
                    menuCat.products = filteredProducts

                    filteredMenu.push(menuCat)
                }
            })

            console.log(filteredMenu, this.props.full_menu)

            //this.props.storeMenu(filteredMenu, "RESTORE_MENU")
        } else if (term.length == 0) {
            this.props.storeMenu(this.props.full_menu, "RESTORE_MENU")
            this.props.fetchCategories()
        }
    }

    toggleCategory = (id) => {
        document
            .querySelector(`#menu-category-${id}`)
            .classList.toggle("active")
        let content = document.querySelector(`#menu-items-${id}`) //
        if (content.style.display === "block") {
            content.style.display = "none"
        } else {
            content.style.display = "block"
        }
    }

    render() {
        const menumain = this.props.menumain

        const { ordersPlaced, placeOrderSearch } = this.props.employeeData
        const { isSubmitting } = this.props.commonData
        const { modalOpen, modalName } = this.props.modalData

        let { config } = this.props.cartdata
        let addedItems = this.props.added_items
        let total = this.props.total
        let totalquantity = 0
        {
            this.props.items &&
                this.props.items.length > 0 &&
                this.props.items.map((item) => {
                    totalquantity += parseInt(item.quantity)
                })
        }

        const menuItems =
            menumain && menumain.length > 0
                ? menumain.map((menu_main) => {
                      const products =
                          menu_main.products && menu_main.products.length > 0
                              ? menu_main.products.map((product) => {
                                    return (
                                        <div
                                            className="menuitems-container grid grid-cols-3 mx-2 mb-2"
                                            key={product.id}
                                        >
                                            <div className="menu-image-container ">
                                                <img
                                                    src={product.image_url}
                                                    alt="logo"
                                                    className="menu-items-image "
                                                />
                                            </div>

                                            <div className="p-2 h-auto menu-notes-menu-page col-span-2">
                                                <h1 className="text-base font-semibold text-gray-800  product-name">
                                                    {product.product}
                                                </h1>

                                                <div className="grid grid-cols-2   ">
                                                    <p className="mail self-center text-sm md:text-current">
                                                        Rs{" "}
                                                        {Math.round(
                                                            product.max_price
                                                        )}
                                                    </p>
                                                    <div className="text-right flex  ">
                                                        {config.orders ==
                                                        "open" ? (
                                                            <>
                                                                <button
                                                                    onClick={() => {
                                                                        this.DecreaseItem(
                                                                            product.id
                                                                        )
                                                                    }}
                                                                    className=" text-gray-800 add-to-cart-menu-btn plus-minus-bntn focus:outline-none"
                                                                >
                                                                    -
                                                                </button>

                                                                <button
                                                                    onClick={() => {
                                                                        this.IncrementItem(
                                                                            product.id
                                                                        )
                                                                    }}
                                                                    className=" text-gray-800 add-to-cart-menu-btn  plus-minus-bntn  focus:outline-none"
                                                                >
                                                                    +
                                                                </button>
                                                            </>
                                                        ) : config.orders ==
                                                          "closed" ? (
                                                            <>
                                                                <button
                                                                    disabled
                                                                    className=" text-gray-800 add-to-cart-menu-btn-diabled-color focus:outline-none"
                                                                >
                                                                    -
                                                                </button>
                                                                <button
                                                                    disabled
                                                                    className=" text-gray-800 add-to-cart-menu-btn-diabled-color     focus:outline-none"
                                                                >
                                                                    +
                                                                </button>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                              : null

                      return (
                          <div
                              key={`cat-${menu_main.name}-${menu_main.id}`}
                              className="mb-6"
                          >
                              <div
                                  className="text-center py-2 px-4 bg-gray-800 text-white cursor-pointer rounded-sm mb-4 place-order-collapsible"
                                  id={`menu-category-${menu_main.id}`}
                                  onClick={() =>
                                      this.toggleCategory(menu_main.id)
                                  }
                              >
                                  <div className="flex justify-between">
                                      <div>{menu_main.name}</div>
                                      <div className="icon"></div>
                                  </div>
                              </div>
                              <div
                                  id={`menu-items-${menu_main.id}`}
                                  className="hidden"
                              >
                                  <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap:4">
                                      {products}
                                  </div>
                              </div>
                          </div>
                      )
                  })
                : null

        return (
            <div id="second" className="content-wrapper p-4">
                {ordersPlaced ? (
                    <div className="flex justify-center">
                        <div>
                            <div className="flex justify-center">
                                <img
                                    src={`${baseurl}restabook/checked.png`}
                                    className=" w-1/2"
                                    alt="complete"
                                />
                            </div>
                            <h1 className="font-bold text-3xl mt-4">
                                Order successfully placed
                            </h1>
                            <div className="flex justify-center mt-4">
                                <a
                                    href={`${baseurl}employee/dashboard`}
                                    className="bg-yellow-600 text-white px-4 py-2"
                                >
                                    View Orders
                                </a>
                                <button
                                    onClick={() => this.takeNewOrder()}
                                    className="bg-gray-800 text-white px-4 py-2 ml-2"
                                >
                                    Take New Order
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="grid grid-cols-10 gap-4">
                            <div className="col-span-10 md:col-span-7">
                                <div className="border border-gray-400 px-4 mb-5">
                                    <div className="flex w-full">
                                        <div className="mt-3">
                                            <FaSearch />
                                        </div>
                                        <input
                                            type="text"
                                            className="p-2 w-full text-black text-base border-gray-400 focus:outline-none"
                                            placeholder="Search Menu..."
                                            value={placeOrderSearch}
                                            onChange={(ev) =>
                                                this.searchMenu(ev)
                                            }
                                        />
                                    </div>
                                </div>
                                {menuItems}
                            </div>
                            <div className="col-span-10 md:col-span-3 md:block hidden">
                                <div className="sticky-cart">
                                    {addedItems && addedItems.length > 0 ? (
                                        <div>
                                            <div>
                                                <h3 className="text-center py-1 px-2 bg-gray-800 text-white mb-4">
                                                    Cart Items
                                                </h3>
                                            </div>
                                            {addedItems.map((item) => (
                                                <div
                                                    key={item.id}
                                                    class="flex mb-2 border-b-2 border-gray-300 pb-2"
                                                >
                                                    {/* <div className="text-sm img-div-remove self-center">
                                                        <img
                                                            src={item.image_url}
                                                            alt="logo"
                                                            className="h-16 w-full"
                                                        />
                                                    </div> */}

                                                    <div className="flex-1 self-center ml-2">
                                                        <div className="text-sm text-gray-800 font-semibold overflow-ellipsis">
                                                            {item.product}
                                                        </div>
                                                        <div className="text-sm text-gray-800  font-semibold img-div-remove">
                                                            Rs{" "}
                                                            {Math.round(
                                                                item.max_price
                                                            )}
                                                        </div>
                                                        <div className="text-sm text-gray-800  font-semibold">
                                                            Qty: {item.quantity}
                                                        </div>
                                                    </div>
                                                    <div className="ml-2 text-sm text-gray-800 font-bold self-center">
                                                        Rs{" "}
                                                        {item.quantity *
                                                            item.max_price}
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            this.handleRemove(
                                                                item.id
                                                            )
                                                        }}
                                                        className="self-center ml-2 text-xl  cursor-pointer  text-red-500 "
                                                        title="Remove"
                                                    >
                                                        <TiDeleteOutline />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="flex justify-end">
                                                <div className="text-bold text-sm">
                                                    Grand Total:{" "}
                                                    <span className="font-bold">
                                                        Rs.{total}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                {isSubmitting ? (
                                                    <button
                                                        type="button"
                                                        disabled
                                                        className="bg-green-700 w-full px-4 py-2 mt-8 text-center-4 text-white cursor-not-allowed"
                                                    >
                                                        Please wait...
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={() =>
                                                            this.confirmOrder()
                                                        }
                                                        className="bg-green-700 w-full px-4 py-2 mt-8 text-center-4 text-white"
                                                    >
                                                        Place Order
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="flex justify-center">
                                                <img
                                                    src={`${baseurl}restabook/cooking.png`}
                                                    className="w-32 mb-4"
                                                    alt="cooking"
                                                />
                                            </div>
                                            <h3 className="text-center text-lg font-bold">
                                                Cart is empty...
                                            </h3>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className=" fixed bottom-0 left-0 w-full bg-yellow-600 text-white md:hidden block">
                            <div className="flex justify-between py-2 px-4">
                                <div className="self-center">
                                    <h3 className="text-center text-lg font-bold">
                                        Cart Items:{" "}
                                        {addedItems && addedItems.length
                                            ? addedItems.length
                                            : 0}
                                    </h3>
                                </div>
                                <div className="">
                                    <ViewCart />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modalData: state.modal,
        employeeData: state.employeeData,
        menumain: state.homepageData.menu,
        added_items: state.cartData.addedItems,
        total: state.cartData.total,
        cartdata: state.homepageData,
        totalquantity: state.cartData.totalquantity,
        commonData: state.commonFunctions,
        full_menu: state.homepageData.full_menu,
    }
}

export default connect(mapStateToProps, {
    addToCart,
    placeOrder,
    employeePlaceOrder,
    storeSearchTerm,
    storeMenu,
    retakeOrders,
    fetchCategories,
})(PlaceOrder)
