import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";
import { connect } from "react-redux";
class Menuitems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
      show: true,
      max: 5,
      min: 0,
    };
  }

  IncrementItem = () => {
    this.setState((prevState) => {
      if (prevState.quantity < 9) {
        return {
          quantity: prevState.quantity + 1,
        };
      } else {
        return null;
      }
    });
  };
  DecreaseItem = () => {
    this.setState((prevState) => {
      if (prevState.quantity > 0) {
        return {
          quantity: prevState.quantity - 1,
        };
      } else {
        return null;
      }
    });
  };
  ToggleClick = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  handleChange = (event) => {
    this.setState({ quantity: event.target.value });
  };
  handleClick = (id) => {
    this.props.addToCart(id);
  };
  render() {
    const { sale, item, category_id, price, image } = this.props;
    return (
      <div className=" menuitems-container grid grid-cols-3  " id={category_id}>
        <div className="menu-image-container ">
          <img
            // src={`${baseurl}restabook/10.jpg`}
            src={image}
            alt="logo"
            className="menu-items-image "
          />
        </div>
        <div className="p-2 md:p-6 h-auto menu-notes-menu-page col-span-2">
          <h1 className="md:text-xl font-semibold text-gray-800">{item}</h1>

          <div className="grid grid-cols-2  mt-6">
            <p className="mail text-sm md:text-current">Rs {price}</p>
            <div className="text-right flex ">
              <button
                // onClick={this.IncrementItem}
                onClick={() => {
                  this.handleClick(item.id);
                }}
                className=" text-gray-800   plus-minus-bntn  focus:outline-none"
              >
                +
              </button>
              <input
                className="add-to-cart-menu-btn text-gray-800 text-xs font-bold m-auto text-center"
                value={this.state.quantity}
                onChange={this.handleChange}
              />
              <button
                onClick={this.DecreaseItem}
                className=" text-gray-800 plus-minus-bntn focus:outline-none"
              >
                -
              </button>
              {/* <button className="add-to-cart-menu-btn text-gray-800 text-xs font-bold m-auto">
                Add To Cart
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menuitems;
