import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";

class OpeningHourMain extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };
  render() {
    return (
      <div className="flower-img ">
        <img
          src={`${baseurl}restabook/7.png`}
          // src="restabook/7.png"
          alt="logo"
          className="flower-img flower-upcoming-img"
        />
        <div className="opening-hours">
          <div className="opening-hour-right-side-div  ">
            <h1 className="our-story-text text-center text-2xl ">
              Call For Reservations
            </h1>
            <h1 className="text-center font-bold text-2xl text-white pt-2">
              Opening Hours
            </h1>
            <h1 className="mail text-center font-bold ">. . . . . .</h1>
            <div className="  gap-4">
              <div>
                <h1 className="mail text-center">Monday to Sunday</h1>
                <h1 className="text-white font-bold text-2xl text-center">
                  11:00 AM
                </h1>
                <h1 className="text-white font-bold text-2xl text-center">
                  09:00 PM
                </h1>
              </div>
              {/* <div></div> */}
              {/* <div>
                <h1 className="mail text-center">Friday to Saturday</h1>
                <h1 className="text-white font-bold text-2xl text-center">
                  11:00
                </h1>
                <h1 className="text-white font-bold text-2xl text-center">
                  19.00
                </h1>
              </div> */}
            </div>
            <div className="grid  grid-cols-3 gap-4">
              <div className="line border"></div>
              <div className="text-white  text-center font-bold">.</div>
              <div className="line border"></div>
            </div>
            <div className="number-text font-bold text-white text-2xl p-4 text-center ">
              7086602496
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OpeningHourMain;
