import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";
import Header from "./Header";

class Navbar extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };

  render() {
    //     window.onscroll = function() {myFunction()};

    // var header = document.getElementById("myHeader");
    // var sticky = header.offsetTop;

    // function myFunction() {
    //   if (window.pageYOffset > sticky) {
    //     header.classList.add("sticky");
    //   } else {
    //     header.classList.remove("sticky");
    //   }
    // }
    return (
      <div class="hero-image-services ">
        <img
          src={`${baseurl}restabook/pexels-ready-made-3850838.jpg`}
          // src="restabook/2.jpg"
          alt="logo"
          className="hero-image-services image"
        />
        <div className="m-10">
          <Header />
          <div className="homepage-div ">
            <div className=" text-2xl text-white pb-2 md:pb-6 ">. . . . .</div>
            {/* <h1 className="homepage-note pb-2 md:pb-6">
              Top Services And Premium Cuisine.
            </h1> */}
            <h1 className="font-bold text-5xl text-white homepage-heading">
              Welcome To <br />{" "}
              <p className="text-3xl md:text-5xl">The Backyard Cafe</p>
            </h1>
            <Link to="/menu">
              <button className="checkout-menu-text mt-2  md:mt-8 text-center text-sm font-semibold">
                Check Out Our Menu
              </button>
            </Link>
          </div>
        </div>
        <div className="brush-img  ">
          <img
            src={`${baseurl}restabook/brush-dec.png`}
            // src="restabook/brush-dec.png"
            alt="logo"
            className="  "
          />
          <div className="blank-space-nav bg-white "></div>
        </div>
      </div>
    );
  }
}

export default Navbar;
