import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl } from "../../Custom";

import Blogitems from "./Blogitems";
// import ImageAbout from "./ImageAbout";
class Blog extends Component {
  render() {
    return (
      <div className="bg-white   menu-container">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 text-justify  animate__animated  animate__backInUp ml-2 mr-2  md:ml-16 md:mr-16   ">
          <Blogitems
            item="Lemon Meringue"
            note="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque.. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque"
            image="restabook/9-1.jpg"
          />
          <Blogitems
            item="Lemon Meringue"
            note="Seasoned with an herb crust, served with au empor scelerisque jus
                     and handcarved to order."
            image="restabook/1.jpg"
          />
          <Blogitems
            item="Lemon Meringue"
            note="Seasoned with an herb crust, served with au empor scelerisque jus
                      and handcarved to order."
            image="restabook/4-3.jpg"
          />
          <Blogitems
            item="Lemon Meringue"
            note="Seasoned with an herb crust, served with au empor scelerisque jus
                      and handcarved to order."
            image="restabook/2-4.jpg"
          />
          <Blogitems
            item="Lemon Meringue"
            note="Seasoned with an herb crust, served with au empor scelerisque jus
                      and handcarved to order."
            image="restabook/3-4.jpg"
          />
          <Blogitems
            item="Lemon Meringue"
            note="Seasoned with an herb crust, served with au empor scelerisque jus
                      and handcarved to order."
            image="restabook/8-1.jpg"
          />
          <Blogitems
            item="Lemon Meringue"
            note="Seasoned with an herb crust, served with au empor scelerisque jus
                      and handcarved to order."
            image="restabook/4-2.jpg"
          />
          <Blogitems
            item="Lemon Meringue"
            note="Seasoned with an herb crust, served with au empor scelerisque jus
                      and handcarved to order."
            image="restabook/1-3.jpg"
          />
          <Blogitems
            item="Lemon Meringue"
            note="Seasoned with an herb crust, served with au empor scelerisque jus
                      and handcarved to order."
            image="restabook/2-1.jpg"
          />
        </div>
      </div>
    );
  }
}

export default Blog;
