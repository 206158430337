import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaSearch } from 'react-icons/fa'

import { toggleProductStatus, storeProducts, storeSearchTerm } from '../../../actions'
import LoadingIcon from '../../includes/btn/LoadingIcon'

class Products extends Component {

    toggleStatus = (id) => {
        this.props.toggleProductStatus(id)
    }

    searchProducts = (ev) => {
        let term = ev.target.value
        this.props.storeSearchTerm(term, "EMPLOYEE_PRODUCTS_SEARCH")

        if(term.length >= 3){
            const filteredProducts = this.props.employeeData.products.filter(product => {
                return product.name.toLowerCase().includes(term.toLowerCase())
            })

            this.props.storeProducts(filteredProducts)
        }else{
            this.props.storeProducts(this.props.employeeData.full_products)
        }
    }

    render() {

        const { products, productsSearch } = this.props.employeeData
        const { isSubmitting } = this.props.commonData;

        return (
            <div id="third" className="content-wrapper p-4">
                <div className='flex justify-end'>
                    <div className='border border-gray-400 px-4 mb-5'>
                        <div className='flex w-full'>
                            <div className='mt-3'><FaSearch /></div>
                            <input type="text" className='p-2 w-full text-black text-base border-gray-400 focus:outline-none' placeholder='Search Orders...' value={productsSearch} onChange={ (ev) => this.searchProducts(ev) } />
                        </div>
                    </div>
                </div>
                <div className='grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1'>
                {
                    products && products.length > 0 ? products.map(product => (
                        <div
                        className="menuitems-container grid grid-cols-3 mx-2 mb-2"
                        key={product.id}
                        >
                            <div className="menu-image-container ">
                                <img
                                src={product.image_url}
                                alt="logo"
                                className="menu-items-image "
                                />
                            </div>

                            <div className="p-2 menu-notes-menu-page col-span-2">
                                <h1 className="text-base font-semibold h-12 text-gray-800  product-name">
                                    {product.name}
                                </h1>
                                <div className="grid grid-cols-2 mt-2">
                                    <div className="text-right flex">
                                        {
                                            product.is_inactive ? (
                                                <button type='button' disabled={ isSubmitting ? true: false } onClick={() => this.toggleStatus(product.id)} className='bg-green-600 text-white px-2 py-1'> { isSubmitting ? <LoadingIcon /> : 'Enable' }</button>
                                            ) : (
                                                <button type='button' disabled={ isSubmitting ? true: false } onClick={() => this.toggleStatus(product.id)} className='bg-red-600 text-white px-2 py-1'>{ isSubmitting ? <LoadingIcon /> : 'Disable' }</button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : (
                        <div></div>
                    )
                }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        employeeData: state.employeeData,
        commonData: state.commonFunctions,
    }
}

export default connect(mapStateToProps, { toggleProductStatus, storeProducts, storeSearchTerm })(Products)