import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";

class Upcomingitems extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };
  render() {
    return (
      <div>
        <h1 className="text-xl text-white text-center pb-4">
          Jazz Band Live Event
        </h1>
        <h1 className="mail text-center text-sm pb-4">25 Dec 2021</h1>
        <h1 className="text-gray-400 font-bold text-xs text-center">
          Sed ut perspiciatis uncle omnis iste natus error sit Sed ut
          perspiciatis uncle omnis iste natus error sit Sed ut perspiciatis
          uncle omnis iste natus error sit
        </h1>
        <h1 className="mail font-bold text-2xl text-center">. . .</h1>
      </div>
    );
  }
}

export default Upcomingitems;
