import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl2 } from "../../Custom";
import { connect } from "react-redux";
import { fetchGallery } from "../../../actions";
import Slider from "react-slick";
import { LeftArrow, RightArrow } from "../../CustomArrows";
class ImageAbout extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };
  componentDidMount() {
    this.props.fetchGallery();
  }
  render() {
    var settings = {
      infinite: true,
      nextArrow: <LeftArrow />,
      prevArrow: <RightArrow />,
      slidesToShow: 1,
      speed: 500,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: true,
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 2,
            autoplay: false,
            nextArrow: <LeftArrow />,
            prevArrow: <RightArrow />,
            infinite: true,
          },
        },
        {
          breakpoint: 620,
          settings: {
            dots: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            nextArrow: <LeftArrow />,
            prevArrow: <RightArrow />,
            infinite: true,
          },
        },
      ],
    };
    const galleries = this.props.galleries;
    const image =
      galleries && galleries.length > 0
        ? galleries.map((gallery_item) => {
            return (
              // <div className="shadow-lg" key={gallery_item.id}>
              // <a href={`${baseurl2}/${gallery_item.image}`}>
              <img
                src={`${baseurl2}/${gallery_item.image}`}
                alt="logo"
                className=" iamge-slider-img-div-homepage shadow-lg h-auto w-auto"

                // onClick={() => show1()}
              />
              // </a>

              // </div>
              //  <Categoriesitems
              //   title={categories_cake.name}
              //   key={categories_cake.id}
              //   title1={categories_cake.name}
              //   image={`${baseurl2}${categories_cake.api_image}`}
              //   newTo={categories_cake.slug}
              // />
            );
          })
        : null;
    return (
      <div className="  main-slide">
        <Slider {...settings}>{image}</Slider>;
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    galleries: state.homepageData.gallery,
  };
};
export default connect(mapStateToProps, { fetchGallery })(ImageAbout);
