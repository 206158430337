import React from "react"

const TermsConditions = () => {
    return (
        <div className="bg-white menu-container py-16 px-4">
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">Terms of use</h1>
                <ul>
                    <li>
                        <p>
                            These terms of use clearly specify your rights and
                            duties while using the service. The use of our
                            website www.thebackyardcafe.in (the "Website") which
                            will be used for availing the services provided by
                            us. The Website is referred to as the “Platform”.
                            Before using the service you are advised to read all
                            the terms of use carefully. By clicking on the I
                            Agree you authorizes us to register you as a
                            Registered User and you shall comply with all the
                            terms and conditions mentioned herein.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">
                    Terms of Service
                </h1>
                <ul>
                    <li>
                        <p>
                            The Backyard Cafe shall not be liable for any
                            defects in food. The Backyard Cafe in case
                            determines the price and the availability of the
                            product by its own.
                        </p>
                    </li>
                    <li>
                        <p>
                            All the prices and availability are made by the
                            merchants. Any extra charges applicable such as
                            container charges, restaurant charges, etc. are
                            managed and controlled by the merchants.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">
                    Account Registration
                </h1>
                <ul>
                    <li>
                        <p>
                            A user shall be required to furnish the following
                            details before using the Service/s of The Backyard
                            Cafe. The user registers themselves from the website
                            by providing the following details:
                            <ul>
                                <li>Name</li>
                                <li>Address</li>
                                <li>Email</li>
                                <li>Contact details</li>
                                <li>Date of Birth</li>
                            </ul>
                        </p>
                    </li>
                    <li>
                        <p>
                            At the time of the registration, the Platform will
                            send an OTP to the mobile no and email address
                            provided by the user. The user shall copy the OTP
                            and put the same in the App. After the verification
                            is complete, the account will be created.
                        </p>
                    </li>
                    <li>
                        <p>
                            A user can create only one account with the same
                            email address and mobile number.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">
                    Cancellation and refund
                </h1>
                <ul className=" list-disc">
                    <li>
                        <h3 className="font-bold text-lg">Cancellation</h3>
                        <p>
                            A user shall not be entitled to cancel any order
                            after it has been confirmed by the merchant. Any
                            cancellation made after the confirmation shall be
                            subject to a cancellation fee up to the amount of
                            the order placed. Such cancellation fee shall be
                            updated on the website in the outstanding section
                            and the user before placing the next order shall
                            clear such outstanding.
                        </p>
                    </li>
                    <li>
                        <h3 className="font-bold text-lg">Refund</h3>
                        <p>
                            No refund shall be generated once the order is
                            confirmed.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">
                    Bookings and Financial Terms
                </h1>
                <ul>
                    <li>
                        <p>
                            The Backyard Cafe accepts payments through UPI and
                            cash only.
                        </p>
                    </li>
                    <li>
                        <p>The Backyard cafe accepts only prepaid orders.</p>
                    </li>
                    <li>
                        <p>
                            Delivery by The Backyard Cafe will be accepted
                            depending on merchant acceptance. And the minimum
                            order value should be Rs. 300.
                        </p>
                    </li>
                    <li>
                        <p>
                            The backyard cafe don't provide online delivery of
                            products.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default TermsConditions
