import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";

class CustomerReview extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };
  render() {
    return (
      <div className="customerreview-container   text-center">
        <p className="our-story-text">What Said About Us</p>
        <p className=" text-gray-700 font-semibold text-3xl">
          Customer Reviews
        </p>
        <p className="  ">. . . . . .</p>
      </div>
    );
  }
}

export default CustomerReview;
