import React from "react"
import { connect } from "react-redux"

import axios from "axios"
import { api } from "../../../api"
import { employeeOrders, printStatus } from "../../../actions"
import { baseurl2 } from "../../Custom"

class OrderItem extends React.Component {
    printSlip = (id) => {
        this.updatePrintStatus(id)

        const url = `${baseurl2}/selling/${id}/slip?package_slip=true`
        axios
            .get(url)
            .then((res) => {
                const data = res.data
                //document.getElementById('receipt_section').innerHTML = data.receipt.html_content;
                //print response html data
                var a = window.open(
                    "",
                    "",
                    "height=" +
                        window.screen.height +
                        ", width=" +
                        window.screen.width
                )
                a.document.write(data.receipt.html_content)
                a.document.close()
                a.print()

                //close after print
                window.onafterprint = function () {
                    a.close()
                }
            })
            .catch((error) => {
                //return error.response;
                alert("Something went wrong. Please refresh the page.")
            })
    }

    updatePrintStatus = (id) => {
        this.props.printStatus(id)
    }

    changeStatus = (status, id) => {
        const url =
            status === "cooked"
                ? `orders/mark-as-cooked/${id}`
                : `orders/mark-as-served/${id}`
        api.get(url)
            .then((res) => {
                //return res.data;
                this.props.employeeOrders()
            })
            .catch((error) => {
                //return error.response;
                alert("Something went wrong. Please refresh the page.")
            })
    }

    render() {
        const {
            order_id,
            invoice_no,
            time,
            customer,
            location,
            status,
            is_printed,
            delivery_time,
        } = this.props

        const dateTime = new Date(time)

        const formatted_time = dateTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        })
        const formatted_date = dateTime.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        })

        const isPrinted = is_printed ? "bg-green-400" : "bg-red-400"

        return (
            <div className="order-item bg-gray-400 pt-4">
                <h3 className="text-center font-bold text-lg">#{invoice_no}</h3>
                <table className="order-info p-4">
                    <tbody>
                        <tr>
                            <td>Placed At</td>
                            <td>
                                {formatted_date} {formatted_time}
                            </td>
                        </tr>
                        <tr>
                            <td>Order Status</td>
                            <td>
                                {status === "received" ? (
                                    <span className="bg-red-500 text-white p-1 text-sm rounded-md">
                                        Received
                                    </span>
                                ) : status === "cooked" ? (
                                    <span className=" bg-yellow-600 text-white p-1 text-sm rounded-md">
                                        Cooking
                                    </span>
                                ) : (
                                    <span className="bg-green-600 text-white p-1 text-sm rounded-md">
                                        Ready
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Customer</td>
                            <td>{customer}</td>
                        </tr>
                        <tr>
                            <td>Delivery Time:</td>
                            <td>{delivery_time}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="w-full mt-4">
                    {/* {
                        status === 'received' ? (
                            <button className='bg-yellow-600 text-center py-1 w-full text-white' onClick={() => this.changeStatus('cooked', order_id)}>Mark as Cooking</button>
                        ) : status === 'cooked' && (
                            <button className='bg-green-600 text-center py-1 w-full text-white' onClick={() => this.changeStatus('ready', order_id)}>Mark as Ready</button>
                        )
                    } */}
                    <button
                        className={`${isPrinted} py-1 w-full text-white`}
                        onClick={() => this.printSlip(order_id)}
                    >
                        Print Kitchen Slip
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orders: state.employeeData.orders,
    }
}

export default connect(mapStateToProps, { employeeOrders, printStatus })(
    OrderItem
)
