import React, { Component } from "react"

import { Link, withRouter } from "react-router-dom"

import { baseurl, openTab, scrollToTop } from "../../Custom"
import Header from "../nav/Header"
import { connect } from "react-redux"
import { removeToCart, addToCart } from "../../../actions"
import Cartitems from "./Cartitems"

// import ImageAbout from "./ImageAbout";
//where is romveto reducer???yes not working
class Cart extends Component {
    componentDidMount() {
        scrollToTop()
    }

    handleRemove = (id) => {
        // this.props.removeToCart(id);
        let addedItems = this.props.added_item
        let total = this.props.total

        const menu = this.props.menumain
        let products = []

        const menu_products =
            menu &&
            menu.map((category) => {
                category.products.length > 0 &&
                    category.products.map((product) => {
                        products.push(product)
                    })
            })
        // let addedItem = products.find((item) => item.id == id);
        let grandTotal = 0
        let existed_item = addedItems.find((item) => id == item.id)
        // console.log(products);
        if (existed_item) {
            let index = addedItems.findIndex(
                (index_product) => index_product.id == existed_item.id
            )
            console.log(index)
            if (addedItems[index].quantity >= 1) {
                addedItems[index].quantity -= 1
                grandTotal = total - parseInt(existed_item.max_price)
            }
            if (addedItems[index].quantity <= 0) {
                addedItems.splice(index, 1)
            }
            // let grandTotal = total - parseInt(existed_item.max_price);
            this.props.addToCart(addedItems, grandTotal)
        }
    }
    render() {
        const cart = this.props.cartdata ? this.props.cartdata : []
        let total = this.props.total
        //  not working now the delte
        let addedItems =
            this.props.items && this.props.items.length > 0 ? (
                this.props.items.map((item) => {
                    return (
                        <div
                            key={item.id}
                            class=" grid grid-cols-4 md:grid-cols-6 "
                        >
                            <div className="text-sm background-product-items img-div-remove ">
                                <img
                                    src={item.image_url}
                                    alt="logo"
                                    className=" h-16 w-full"
                                />
                            </div>

                            <div className="text-xs background-description-items  text-gray-800 font-bold">
                                {item.product}
                            </div>
                            <div className="text-xs background-product-items   text-gray-800  font-bold img-div-remove">
                                Rs {Math.round(item.max_price)}
                            </div>
                            <div className="text-xs background-description-items  text-gray-800  font-bold">
                                {item.quantity}
                            </div>
                            <div className="text-xs background-product-items text-gray-800    font-bold">
                                Rs {item.quantity * item.max_price}
                            </div>
                            <div
                                onClick={() => {
                                    this.handleRemove(item.id)
                                }}
                                className="  background-description-items cursor-pointer  text-gray-800   mail"
                            >
                                x
                            </div>
                        </div>
                    )
                })
            ) : (
                <div className="text-center p-10 font-bold text-2xl">
                    Cart Is Empty{" "}
                </div>
            )

        return (
            <div className=" bg-white  menu-container">
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6 cartitems">
                    <div className="col-span-2">
                        <h1 className="text-2xl font-semibold mb-10 md:pl-0 pl-4">
                            My Cart{" "}
                            <span className="mail">
                                {" "}
                                (
                                {addedItems && addedItems.length
                                    ? addedItems.length
                                    : "0"}
                                ) items
                            </span>
                        </h1>
                        <div>
                            <div className="grid grid-cols-4 md:grid-cols-6">
                                <div className="text-sm background-product text-gray-700 img-div-remove ">
                                    Product
                                </div>
                                <div className="text-sm background-description text-gray-700">
                                    Description
                                </div>
                                <div className="text-sm background-product text-gray-700 img-div-remove">
                                    Price
                                </div>
                                <div className="text-sm background-description text-gray-700">
                                    Qty
                                </div>
                                <div className="text-sm background-product text-gray-700">
                                    Subtotal
                                </div>
                                <div className="text-sm background-description ">
                                    {" "}
                                </div>
                            </div>
                            <div className="scrollmenu11 ">
                                {addedItems}
                                {/* <Cartitems
                  item="Fish pie"
                  price="120"
                  total="120"
                  image="restabook/3-4.jpg"
                />
                <Cartitems
                  item="Fish pie"
                  price="120"
                  total="120"
                  image="restabook/3-4.jpg"
                />
                <Cartitems
                  item="Fish pie"
                  price="120"
                  total="120"
                  image="restabook/3-4.jpg"
                />
                <Cartitems
                  item="Fish pie"
                  price="120"
                  total="120"
                  image="restabook/3-4.jpg"
                /> */}
                            </div>
                        </div>

                        {/* <div className="flex gap-2 mt-8">
                  <input
                    placeholder="Coupon Code"
                    className="pl-10 pr-10  h-12 self-center mt-8 bg-gray-100 text-black  text-xs"
                  />
                  <button className=" explore-btn text-white    font-bold pl-10 pr-10   explore-btn  text-xs">
                    Apply Coupon
                  </button>
                </div> */}
                    </div>
                    <div>
                        <div className="cart-item-right-container lg:mt-0 mt-6">
                            <div className="cart-item-right-side">
                                <h1 className="mail font-bold text-xl pb-6">
                                    Cart Totals
                                </h1>
                                {/* <div className="grid grid-cols-3  ">
                  <div className="col-span-2">
                    <h1 className="  font-semibold">SubTotal</h1>
                    <div className="line-cart  pt-4"></div>
                  </div>
                  <h1 className="mail  text-right text-xs  "> Rs 200</h1>
                </div> */}
                                <div className="grid grid-cols-3 mt-6 ">
                                    <div className="col-span-2">
                                        <h1 className="  font-semibold">
                                            Total
                                        </h1>
                                        <div className="line-cart  pt-4"></div>
                                    </div>
                                    <h1 className="mail text-right text-xs  ">
                                        Rs
                                        {total}
                                    </h1>
                                </div>
                                {addedItems && addedItems.length ? (
                                    <Link to="/checkout">
                                        <button className=" focus:outline-none checkout-btn mt-8 font-bold text-xs">
                                            Proceed To Checkout
                                        </button>
                                    </Link>
                                ) : (
                                    <button className=" focus:outline-none checkout-btn mt-8 font-bold text-xs">
                                        Add Items To Proceed
                                    </button>
                                )}
                                {/* {addedItems && addedItems.length == 0 ? (
                  <button className=" focus:outline-none checkout-btn mt-8 font-bold text-xs">
                    Add Items To Proceed
                  </button>
                ) : (
                  <Link to="/checkout">
                    <button className=" focus:outline-none checkout-btn mt-8 font-bold text-xs">
                      Proceed To Checkout
                    </button>
                  </Link>
                )} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.cartData.addedItems,
        total: state.cartData.total,
        menumain: state.homepageData.menu,
        added_item: state.cartData.addedItems,
    }
}

export default connect(mapStateToProps, { removeToCart, addToCart })(Cart)
