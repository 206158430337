import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab, scrollToTop } from "../../Custom";
import { connect } from "react-redux";
import {
  fetchCategories,
  addToCart,
  fetchPickedupLocation,
} from "../../../actions";

// import Menuitems from "./Menuitems";
// import LoginMenu from "../nav/login/LoginMenu";
// import AddToLogin from "../nav/login/AddToLogin";
import { Collapse } from "react-collapse";
class MenuMain2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: this.props.isOpen,
      open: false,
    };
  }
  onItemClick = (id) => {
    this.setState({ isOpened: !this.state.isOpened });
  };
  componentDidMount() {
    this.props.fetchCategories();
    this.props.fetchPickedupLocation();
    scrollToTop();
  }

  IncrementItem = (id) => {
    // this.props.addToCart(id);
    let addedItems = this.props.added_item;
    let total = this.props.total;

    const menu = this.props.menumain;
    let products = [];

    const menu_products =
      menu &&
      menu.map((category) => {
        category.products.length > 0 &&
          category.products.map((product) => {
            products.push(product);
          });
      });
    let addedItem = products.find((item) => item.id == id);
    let existed_item = addedItems.find((item) => id == item.id);
    // console.log(products);
    if (existed_item) {
      let index = addedItems.findIndex(
        (index_product) => index_product.id == addedItem.id
      );
      console.log(index);
      addedItems[index].quantity += 1;
    } else {
      addedItem.quantity = 1;
      addedItems = [...addedItems, addedItem];
    }
    const grandTotal = total + parseInt(addedItem.max_price);
    this.props.addToCart(addedItems, grandTotal);
  };

  DecreaseItem = (id) => {
    // this.props.addToCart(id);
    let addedItems = this.props.added_item;
    let total = this.props.total;

    const menu = this.props.menumain;
    let products = [];

    const menu_products =
      menu &&
      menu.map((category) => {
        category.products.length > 0 &&
          category.products.map((product) => {
            products.push(product);
          });
      });
    // let addedItem = products.find((item) => item.id == id);
    let grandTotal = 0;
    let existed_item = addedItems.find((item) => id == item.id);
    // console.log(products);
    if (existed_item) {
      let index = addedItems.findIndex(
        (index_product) => index_product.id == existed_item.id
      );
      console.log(index);
      if (addedItems[index].quantity >= 1) {
        addedItems[index].quantity -= 1;
        grandTotal = total - parseInt(existed_item.max_price);
      }
      if (addedItems[index].quantity <= 0) {
        addedItems.splice(index, 1);
      }
      // let grandTotal = total - parseInt(existed_item.max_price);
      this.props.addToCart(addedItems, grandTotal);
    }
  };
  render() {
    const { isOpened } = this.state;
    const { title, content1, content2 } = this.props;
    const showDetails = (button) => {
      //   var e = document.querySelector(div);
      //   if (e.style.display == "block") {
      //     e.style.display = "none";
      //   } else {
      //     e.style.display = "block";
      //   }

      var x = document.querySelector(button);
      if (x.innerHTML === "-") {
        x.innerHTML = "+";
      } else {
        x.innerHTML = "-";
      }
    };

    // i have put one inside one okay leme rerad ok Actucode looks ok for me accordion is below
    const { login, loginLoading, user, isLoggedIn } = this.props.user;
    const { plus, timday, id } = this.props;
    const menumain = this.props.menumain;
    const menu =
      menumain && menumain.length > 0
        ? menumain.map((menu_main) => {
            let plus = menu_main.id;
            let timday = menu_main.id;
            let id = menu_main.id;
            let isOpen = true;
            const products =
              menu_main.products && menu_main.products.length > 0
                ? menu_main.products.map((product) => {
                    let { config } = this.props.cartdata;
                    // let plus = product.id;
                    // let timday = product.id;

                    return (
                      <Collapse
                        isOpened={isOpened}
                        className="content"
                        key={product.id}
                      >
                        {isOpened && (
                          <div className=" menuitems-container grid grid-cols-3  ">
                            <div className="menu-image-container ">
                              <img
                                src={product.image_url}
                                alt="logo"
                                className="menu-items-image "
                              />
                            </div>

                            <div className="p-2  h-auto menu-notes-menu-page col-span-2">
                              <h1 className="md:text-xl font-semibold text-gray-800 h-16">
                                {product.product}
                              </h1>

                              <div className="grid grid-cols-2   ">
                                <p className="mail self-center text-sm md:text-current">
                                  Rs {product.max_price}
                                </p>
                                <div className="text-right flex  ">
                                  {config.orders == "open" ? (
                                    <>
                                      <button
                                        onClick={() => {
                                          this.DecreaseItem(product.id);
                                        }}
                                        className=" text-gray-800 add-to-cart-menu-btn plus-minus-bntn focus:outline-none"
                                      >
                                        -
                                      </button>
                                      {!loginLoading && !login ? (
                                        // <AddToLogin />
                                        <>ujhukyhik</>
                                      ) : !loginLoading && login ? (
                                        <button
                                          onClick={() => {
                                            this.IncrementItem(product.id);
                                          }}
                                          className=" text-gray-800 add-to-cart-menu-btn  plus-minus-bntn  focus:outline-none"
                                        >
                                          +
                                        </button>
                                      ) : null}
                                    </>
                                  ) : config.orders == "closed" ? (
                                    <>
                                      <button
                                        disabled
                                        className=" text-gray-800 add-to-cart-menu-btn-diabled-color  focus:outline-none"
                                      >
                                        -
                                      </button>
                                      <button
                                        disabled
                                        className=" text-gray-800 add-to-cart-menu-btn-diabled-color     focus:outline-none"
                                      >
                                        +
                                      </button>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Collapse>
                    );
                  })
                : null;

            return (
              <>
                <div
                  onClick={() => showDetails(`.button-${plus}`)}
                  className=" menu-grid-menu-page grid   lg:grid-cols-8 ml-2 mr-2 lg:ml-32 lg:mr-32 gap-4 mb-6 mt-6"
                >
                  <div className="line-menu self-center"></div>
                  <div
                    onClick={this.onItemClick}
                    id={id}
                    key={menu_main.id}
                    className="   menu-grid   lg:col-span-6   md:gap-2  "
                  >
                    {/* <div className="    "> */}
                    <button
                      type="button"
                      // id={`${tab}`}
                      className={` flex justify-between w-full text-sm lg:text-xl text-gray-700  focus:outline-none   pl-4 self-center `}
                      //   onClick={() =>
                      //     showDetails(`.div-${timday}`, `.button-${plus}`)
                      //   }
                    >
                      <span className="text-xs self-center">
                        0{menu_main.id}.
                      </span>
                      <span className="  self-center"> {menu_main.name} </span>
                      <div
                        className={` button-${plus} pr-4 text-2xl    self-center`}
                      >
                        +
                      </div>
                    </button>
                    {/* </div> */}
                  </div>
                  <div className="line-menu self-center"></div>
                </div>
                <div className={`  demo4  flex menu-right-side`}>
                  <div>
                    <div
                      // id={`button-${alldishes}`}
                      className="    ml-2 mr-2 md:ml-6 md:mr-6 lg:ml-16 lg:mr-16      "
                    >
                      {/* <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"> */}
                      {products}
                    </div>
                  </div>
                </div>
              </>
            );
          })
        : null;
    let { config } = this.props.cartdata;
    return (
      <div className="bg-white   menu-container">
        {config.orders == "open" ? (
          " "
        ) : config.orders == "closed" ? (
          <div className="text-center font-semibold text-gray-600 text-2xl">
            Order is closed
          </div>
        ) : null}

        {menu}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menumain: state.homepageData.menu,
    added_item: state.cartData.addedItems,
    total: state.cartData.total,
    user: state.registerData,
    cartdata: state.homepageData,
  };
};
export default connect(mapStateToProps, {
  fetchCategories,
  addToCart,
  fetchPickedupLocation,
})(MenuMain2);
