import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

class MainDishes extends Component {
  render() {
    const { number, item, note, price } = this.props;
    return (
      <div className="grid grid-cols-4 md:grid-cols-6 pb-10">
        <h1 className="mail text-center text-sm">{number}.</h1>
        <div className="col-span-2 md:col-span-4">
          <div className="grid grid-cols-4">
            <h1 className="text-white font-semibold  menu-item ">{item}</h1>
            <div className="menu-line col-span-3  "></div>
          </div>
          <h1 className="text-xs text-gray-500">{note}</h1>
        </div>
        <div className="mail   pl-0 md:pl-4">Rs {price}</div>
      </div>
    );
  }
}

export default MainDishes;
