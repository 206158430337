import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab } from "../../Custom";
import Header from "../nav/Header";
import OpeningHourMain from "../nav/OpeningHourMain";
// import Form from "./Form";
// import OrderDetails from "./OrderDetails";
// import ImageAbout from "./ImageAbout";
class ContactHeader extends Component {
  render() {
    return (
      <div class="hero-image-contact ">
        <div class="mapouter bg-black ">
          <div class="gmap_canvas">
            <iframe
              class="gmap_iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Ustm&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>

        <div className="m-10">
          <Header />
          <div className="contact-homepage-div  text-center ">
            <div className="cart-item-right-container">
              <div className="contact-item-right-side">
                <h1 className="mail md:text-xl font-semibold text-center">
                  Contacts Details
                </h1>
                <div className="flex gap-4 text-sm mt-2">
                  <h1>Address : USTM,Meghalaya</h1>
                </div>
                <div className="flex gap-4 text-sm mt-2">
                  <h1>Phone : 7086602496</h1>
                </div>
                <div className="flex   text-sm mt-2">
                  <h1>
                    Mail :{" "}
                    <span className="lowercase text-xs ">
                      admin@thebackyardcafe.in
                    </span>
                  </h1>
                </div>
                <h1 className="mail text-center">. . .</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="brush-img-contact ">
          <img
            src={`${baseurl}restabook/brush-dec.png`}
            // src="restabook/brush-dec.png"
            alt="logo"
            className=" "
          />
          <div className="blank-space   "></div>
        </div>
      </div>
    );
  }
}

export default ContactHeader;
