import React, { Component } from 'react'
import EmployeeHeader from '../EmployeeHeader'
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { connect } from 'react-redux';

import ErrorMsg from "../../includes/inputs/ErrorMsg";
import './employee-login.css'
import { employeeLogin } from '../../../actions'
import { baseurl } from '../../Custom';

export class Login extends Component {

    myTextInput = ({ name, field, placeholder, type }) => {
        return (
            <div className="w-full">
                <label className=" text-base text-gray-700 font-bold mb-3">
                    {placeholder}
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder={placeholder}
                  type={type}
                  name={name} {...field} />
            </div>
        );
    }

    submitForm = (values, actions) => {
        this.props.employeeLogin(values, actions);
    }

    render() {

        const { isSubmitting } = this.props.commonData;
        const { isLoggedIn } = this.props.employeeData;

        if (isLoggedIn) {
            window.location.href = `${baseurl}employee/dashboard`;
        }

        return (
        <>
            <EmployeeHeader title="Employee Login" image="restabook/12.jpg" />
            <div className="bg-white menu-container py-16 px-4">
                <div className="flex flex-col items-center justify-center">
                    <div className="w-full max-w-md">
                        <Formik
                            initialValues={{
                                username: "",
                                password: "",
                            }}
                            onSubmit={(values, actions) => {
                                this.submitForm(values, actions);
                            }}
                            enableReinitialize={true}
                            validationSchema={Yup.object().shape({
                                username: Yup.string().required("Required Field"),
                                password: Yup.string().required("Required Field"),
                            })}
                        >
                            {(props: FormikProps<any>) => {
                            
                            return (
                                <Form>
                                    <div className="bg-blue-100 border border-gray-300 shadow-md rounded px-8 pt-6 pb-8">
                                        <div className="mb-4 employee-login">
                                            <Field
                                                placeholder="Username"
                                                className="  focus:outline-none border-2 bg-gray-200 w-full p-2 "
                                                component={this.myTextInput}
                                                name="username"
                                                type="text"
                                            />
                                            {props.touched.username && props.errors.username && (
                                                <ErrorMsg msg={props.errors.username} />
                                            )}
                                        </div>
                                        <div className="mb-6 employee-login">
                                            <Field
                                                placeholder="Password"
                                                className="  focus:outline-none border-2 bg-gray-200 w-full p-2 "
                                                component={this.myTextInput}
                                                name="password"
                                                type="password"
                                            />
                                            {props.touched.password && props.errors.password && (
                                                <ErrorMsg msg={props.errors.password} />
                                            )}
                                        </div>
                                        <div className="flex justify-center">
                                            <button disabled={isSubmitting ? true : false} className=" bg-yellow-600 w-full hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                                { isSubmitting ?  'Please wait...' : 'Sign In' }
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        employeeData: state.employeeData,
        commonData: state.commonFunctions,
    }
}

export default connect(mapStateToProps, { employeeLogin })(Login)