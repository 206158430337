import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";
import Slider from "react-slick";
import { LeftArrow, RightArrow } from "../../CustomArrows";
import Upcomingitems from "./Upcomingitems";
class Upcoming extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };
  render() {
    var settings = {
      infinite: true,

      slidesToShow: 2,
      speed: 500,

      nextArrow: <LeftArrow />,
      prevArrow: <RightArrow />,

      responsive: [
        {
          breakpoint: 620,
          settings: {
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,

            infinite: true,
            nextArrow: <LeftArrow />,
            prevArrow: <RightArrow />,
          },
        },
      ],
    };
    return (
      <div className="  upcoming-container-div ">
        <div className="flower-img ">
          <img
            src={`${baseurl}restabook/7.png`}
            // src="restabook/7.png"
            alt="logo"
            className="upcoming-flower-img flower-upcoming-img"
          />
          <div className="upcoming-hours">
            <div className="upcoming-right-side-div   ">
              <h1 className="our-story-text text-center text-xl lg:text-2xl pt-4 lg:pt-10 ">
                Plan An Event
              </h1>
              {/* <h1 className="text-center font-bold text-xl lg:text-2xl text-white pt-2">
                {" "}
                Upcoming Events
              </h1>
              <h1 className="mail text-center font-bold ">. . . . . .</h1>

              <div className="main-slide mt-6">
                <Slider {...settings}>
                  <Upcomingitems />
                  <Upcomingitems />
                  <Upcomingitems />
                  <Upcomingitems />
                  <Upcomingitems />
                  <Upcomingitems />
                  <Upcomingitems />
                </Slider>
              </div>
              <div className="grid grid-cols-3">
                <div className="upcoming-line border text-center"></div>
                <div className="text-white  text-center font-bold">.</div>
                <div className="upcoming-line1 border text-center"></div>
              </div> */}

              <div className="   text-center">
                <a href={`${baseurl}enquiry`}>
                  <button className="book-table-text font-bold text-white focus:outline-none   text-center ">
                    Click Here
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="upcoming-flower-img1">
            <img
              src={`${baseurl}restabook/10.jpg`}
              // src="restabook/10.jpg"
              alt="logo"
              className=" upcoming-flower-img1 image"
            />
            <div className=" about-homepage-div text-white text-center">
              <p className="mail font-semibold text-2xl pb-2 md:pb-8">
                Our Story
              </p>
              <p className="homepage-note text-xl font-semibold pb-2 md:pb-6">
                Want to order food home? Visit our online store.
              </p>
              <button className=" buy-online-btn text-white  text-sm font-bold">
                Buy Online
              </button>
            </div>
          </div> */}
      </div>
    );
  }
}

export default Upcoming;
