import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab } from "../../Custom";
import Orderitems from "./Orderitems";
import { connect } from "react-redux";
// import ImageAbout from "./ImageAbout";
class OrderDetails extends Component {
  render() {
    let total = this.props.total;
    let addedItems =
      this.props.items && this.props.items.length > 0
        ? this.props.items.map((item) => {
            // let grandTotal = total + parseInt(item.max_price);
            return (
              // <div key={item.id} class=" grid grid-cols-4 md:grid-cols-6 ">

              //   <div className="text-xs background-description-items  text-gray-800 font-bold">
              //     {item.product}
              //   </div>
              //   <div className="text-xs background-product-items   text-gray-800  font-bold img-div-remove">
              //     Rs {item.max_price}
              //   </div>
              //   <div className="text-xs background-description-items  text-gray-800  font-bold">
              //     {item.quantity}
              //   </div>
              //   <div className="text-xs background-product-items text-gray-800    font-bold">
              //     Rs {total}
              //   </div>

              // </div>
              <div className="grid grid-cols-3 mt-6 ">
                <div className="col-span-2">
                  <h1 className="  font-semibold">
                    {item.product} x {item.quantity}
                  </h1>
                  <div className="line-cart  pt-4"></div>
                </div>
                <h1 className="mail  text-right text-xs  ">
                  Rs {item.quantity * item.max_price}
                </h1>
              </div>
            );
          })
        :  window.location.href = `${baseurl}menu`;;
     
    return (
      <div className="cart-item-right-container  ">
        <div className="cart-item-right-side">
          <div className="grid grid-cols-3  ">
            <div className="col-span-2">
              <h1 className="  font-semibold">Product</h1>
              <div className="line-cart  pt-4"></div>
            </div>
            <h1 className="mail  text-right text-xs  "> SubTotal</h1>
          </div>
          {addedItems}
          {/* <Orderitems item="Miso chicken  × 1" price="120" />
          <Orderitems item="Fish pie  × 1" price="120" /> */}

          {/* <div className="grid grid-cols-3  mt-6">
            <div className="col-span-2">
              <h1 className="  font-semibold">SubTotal</h1>
              <div className="line-cart  pt-4"></div>
            </div>
            <h1 className="mail  text-right text-xs  "> Rs 240</h1>
          </div> */}
          <div className="grid grid-cols-3 mt-6 ">
            <div className="col-span-2">
              <h1 className="  font-semibold">Total</h1>
              <div className="line-cart  pt-4"></div>
            </div>
            <h1 className="mail text-right text-xs  ">Rs {total}</h1>
          </div>
          {/* <button className="checkout-btn mt-8 font-bold text-xs">
            Proceed To Checkout
          </button> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cartData.addedItems,
    total: state.cartData.total,
  };
};

export default connect(mapStateToProps)(OrderDetails);
