import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab } from "../../Custom";
import Header from "../nav/Header";

// import ImageAbout from "./ImageAbout";
class Cartitems extends Component {
  render() {
    const { item, price, total, image, quantity } = this.props;
    return (
      <div class=" grid grid-cols-4 md:grid-cols-8 ">
        <div className="text-sm background-product-items img-div-remove ">
          <img
            // src={`${baseurl}restabook/10.jpg`}
            src={image}
            alt="logo"
            className=" h-16 w-full"
          />
        </div>

        <div className="text-xs background-description-items  text-gray-800 font-bold">
          {item}
        </div>
        <div className="text-xs background-product-items   text-gray-800  font-bold img-div-remove">
          Rs {price}
        </div>
        <div className="text-xs background-description-items  text-gray-800  font-bold">
          {quantity}
        </div>
        <div className="text-xs background-product-items text-gray-800    font-bold">
          Rs {total}
        </div>
        <div className="  background-description-items  text-gray-800   mail">
          x
        </div>
      </div>
    );
  }
}

export default Cartitems;
