import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab } from "../../Custom";
import Header from "../nav/Header";
import Cartitems from "./Cartitems";

// import ImageAbout from "./ImageAbout";
class HeaderCart extends Component {
  render() {
    return (
      <div class="hero-image-services ">
        <img
          src={`${baseurl}restabook/17.jpg`}
          // src="restabook/17.jpg"
          alt="logo"
          className="hero-image-services image"
        />
        <div className="m-10">
          <Header />
          <div className="cart-homepage-div text-center ">
           
            <h1 className="font-bold text-4xl text-white homepage-heading mt-4">
              My Cart
            </h1>
            <div className="font-bold text-xl mail pb-6 ">. . . . .</div>
          </div>
        </div>
        <div className="brush-img-about ">
          <img
            src={`${baseurl}restabook/brush-dec.png`}
            // src="restabook/brush-dec.png"
            alt="logo"
            className=" "
          />
          <div className="blank-space bg-white "></div>
        </div>
      </div>
    );
  }
}

export default HeaderCart;
