import React, { Component } from 'react'
import { connect } from 'react-redux'

import './dashboard.css'
import Orders from './Orders';
import { employeeOrders, fetchCategories, checkEmployee, fetchProducts, fetchPickedupLocation } from '../../../actions'
import PlaceOrder from './PlaceOrder';
import Products from './Products';
import Header from './Header';

class Dashboard extends Component {

    componentDidMount(){
        this.props.checkEmployee().then(() => {
            document.querySelector('.footer').style.display = 'none';
            this.props.fetchPickedupLocation()
            this.callAPI('first')
            this.changeTabs()
        })
    }

    changeTabs = () => {
        const tabs = document.querySelectorAll('#tabs li');
        const contents = document.querySelectorAll('#tab-contents .content-wrapper');
        tabs.forEach((tab, index) => {
            tab.addEventListener('click', () => {
                tabs.forEach(t => t.classList.remove('active'));
                tab.classList.add('active');

                const active_tab = tab.dataset.activate;

                this.callAPI(active_tab)

                contents.forEach(c => c.classList.remove('active'));
                contents[index].classList.add('active');
            } )
        } )
    }

    callAPI = (tab) => {
        switch(tab){
            case 'first': {
                this.props.employeeOrders()
                break
            }
            case 'second': {
                this.props.fetchCategories()
                break
            }
            case 'third': {
                this.props.fetchProducts()
                break
            }
            default:{
                //this.props.employeeOrders()
            }
        }
    }

    render() {

        const { orders } = this.props.employeeData

        return (
            <div className='bg-blue-200' style={{ minHeight: '100vh' }}>
                <Header />
                <div className='container mx-auto py-8'>
                    <div className="rounded border mx-auto my-4 bg-white">
                        <ul id="tabs" className="bg-yellow-600 inline-flex pt-2 px-1 w-full border-b">
                            <li className="active px-4 font-semibold py-2 rounded-t" data-activate="first"><a id="default-tab" href="#0" >Orders</a></li>
                            <li className="px-4 font-semibold py-2 rounded-t" data-activate="second"><a href="#0">Take New Order</a></li>
                            <li className="px-4 font-semibold py-2 rounded-t" data-activate="third"><a href="#0">Products</a></li>
                        </ul>
                        <div id="tab-contents">
                            <Orders orders={orders} />
                            <PlaceOrder />
                            <Products />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        employeeData: state.employeeData,
    }
}

export default connect(mapStateToProps, {employeeOrders, fetchCategories, checkEmployee, fetchProducts, fetchPickedupLocation})(Dashboard)