import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab } from "../../Custom";
import Desserts from "./Menu/Desserts";
import Drinks from "./Menu/Drinks";
import MainDishes from "./Menu/MainDishes";
import Seafood from "./Menu/Seafood";
import Starter from "./Menu/Starter";
class Specialities extends Component {
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.user.login !== this.props.user.login) {

  //     window.location.href = `${baseurl}`;
  //   }
  // };

  render() {
    return (
      <div className="specialities-container text-white ">
        <img
          src={`${baseurl}restabook/brush-dec_2.png`}
          // src="restabook/brush-dec_2.png"
          alt="logo"
          className=""
        />
        <p className="our-story-text text-center pt-10">Special menu offers.</p>
        <p className="text-white font-semibold text-3xl text-center">
          Enjoy Restaurants Specialties
        </p>
        <p className="mail text-center pb-10">. . . . . .</p>
        <div className="  grid grid-cols-1 lg:grid-cols-6 pl-0 lg:pl-32">
          <div className="  speciality-items-grid  m-auto gap-4 ">
            <Link to="#">
              <div className=" pb-10  text-center lg:text-left">
                <button
                  type="button"
                  id="tab-00"
                  className="tablinks active md:m-0  focus:outline-none      "
                  onClick={() => openTab("maindishes", "tab-00")}
                >
                  <span className="text-xs">01.</span> Main Dishes
                </button>
              </div>
            </Link>

            <Link to="#">
              <div className="pb-10 text-center lg:text-left">
                <button
                  type="button"
                  id="tab-11"
                  className="tablinks  md:m-0   focus:outline-none   "
                  onClick={() => openTab("starter", "tab-11")}
                >
                  <span className="text-xs">02.</span> Starter
                </button>
              </div>
            </Link>

            <Link to="#">
              <div className=" pb-10 text-center lg:text-left">
                <button
                  type="button"
                  id="tab-22"
                  className="tablinks md:m-0   focus:outline-none       "
                  onClick={() => openTab("seafood", "tab-22")}
                >
                  <span className="text-xs">03.</span> Sea Food
                </button>
              </div>
            </Link>
            <Link to="#">
              <div className="pb-10  text-center lg:text-left">
                <button
                  type="button"
                  id="tab-33"
                  className="tablinks  md:m-0   focus:outline-none   "
                  onClick={() => openTab("desserts", "tab-33")}
                >
                  <span className="text-xs">04.</span> Desserts
                </button>
              </div>
            </Link>

            <Link to="#">
              <div className="pb-10  text-center lg:text-left">
                <button
                  type="button"
                  id="tab-44"
                  className="tablinks md:m-0   focus:outline-none       "
                  onClick={() => openTab("drinks", "tab-44")}
                >
                  <span className="text-xs">05.</span> Drinks
                </button>
              </div>
            </Link>
          </div>
          {/* tab contents */}
          <div className="middile-line"></div>
          <div className="col-span-4 flex menu-right-side">
            <div>
              <div
                id="maindishes"
                className="tabcontent active     w-auto       "
              >
                <MainDishes
                  number="01"
                  item="Miso Chicken"
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <MainDishes
                  number="02"
                  item="Fish Pie"
                  note="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
                  price="190"
                />
                <MainDishes
                  number="03"
                  item="Garden Salad"
                  note="Seasoned with an herb crust, served with au empor scelerisque jus and handcarved to order."
                  price="190"
                />
                <MainDishes
                  number="04"
                  item="Soft Shell Crab"
                  note="Seasoned with an herb crust, served with au empor scelerisque jus and handcarved to order."
                  price="190"
                />
              </div>
              <div id="starter" className="tabcontent             ">
                <Starter
                  number="01"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Starter
                  number="02"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Starter
                  number="03"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Starter
                  number="04"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
              </div>
              <div id="seafood" className="tabcontent        ">
                <Seafood
                  number="01"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Seafood
                  number="02"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Seafood
                  number="03"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Seafood
                  number="04"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
              </div>

              <div id="desserts" className="tabcontent              ">
                <Desserts
                  number="01"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Desserts
                  number="02"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Desserts
                  number="03"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Desserts
                  number="04"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
              </div>
              <div id="drinks" className="tabcontent        ">
                <Drinks
                  number="01"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Drinks
                  number="02"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Drinks
                  number="03"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
                <Drinks
                  number="04"
                  item="Lemon Meringue                "
                  note="Granny help you treat yourself with a empor scelerisque different
            meal everyday."
                  price="190"
                />
              </div>
            </div>
          </div>
        </div>
        <img
          src={`${baseurl}restabook/brush-dec.png`}
          // src="restabook/brush-dec.png"
          alt="logo"
          className=""
        />
      </div>
    );
  }
}

export default Specialities;
