import React from "react"

const DeliveryPolicy = () => {
    return (
        <div className="bg-white menu-container py-16 px-4">
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">
                    Delivery Policy
                </h1>
                <p>
                    The Backyard Cafe reserves its rights of food delivery and
                    is subjected to availability of delivery executives during
                    the placement of the order by the customer.
                </p>
            </div>
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">
                    Serviceable Area
                </h1>
                <p>
                    The food delivery option is only restricted for the
                    University Of Science and Technology, Meghalaya campus
                    itself and no delivery will be made if any order is placed
                    outside the University premises.
                </p>
            </div>
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">
                    Delivery Charge
                </h1>
                <p>
                    Delivery will be free of any charge for orders above the
                    volume of 1000 INR.
                </p>
            </div>
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">
                    Delivery Methods
                </h1>
                <p>
                    The Backyard Cafe is providing the service of Take Away
                    orders from 11 AM till 8:30 PM and delivery options from 5
                    PM to 8 PM.
                </p>
            </div>
            <div className="tc-pp-section">
                <h1 className="text-xl md:text-2xl font-bold">
                    Return and Replacement Policy
                </h1>
                <p>
                    No order can be returned or cancelled after placing the
                    order, however the order can be replaced with other items
                    within 5mins of placing the order. The same can be informed
                    by dialing the number{" "}
                    <a
                        href="tel:917086602496"
                        className=" text-blue-700 underline"
                    >
                        7086602496
                    </a>
                </p>
            </div>
        </div>
    )
}

export default DeliveryPolicy
