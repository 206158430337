import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab } from "../../Custom";
import Header from "../nav/Header";

import Menuitems from "./Menuitems";
// import ImageAbout from "./ImageAbout";
class MenuHeader extends Component {
  render() {
    return (
      <div class="hero-image-services ">
        <img
          src={`${baseurl}restabook/12.jpg`}
          // src="restabook/12.jpg"
          alt="logo"
          className="hero-image-services image"
        />
        <div className="m-10">
          <Header />
          <div className="menu-homepage-div text-center ">
            <h1 className="our-story-text text-xl md:pb-4 ">
              Special menu offers.
            </h1>
            <h1 className="font-bold text-4xl text-white homepage-heading">
              Discover Our menu
            </h1>
            <div className="font-bold text-xl mail pb-6 ">. . . . .</div>
          </div>
        </div>
        <div className="brush-img-about ">
          <img
            src={`${baseurl}restabook/brush-dec.png`}
            // src="restabook/brush-dec.png"
            alt="logo"
            className=" "
          />
          <div className="blank-space bg-white "></div>
        </div>
      </div>
    );
  }
}

export default MenuHeader;
