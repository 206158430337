import React, { Component } from "react"
import { Form, Formik, Field } from "formik"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import {
    fetchPickedupLocation,
    placeOrder,
    setOrderNote,
    setDelivery,
    setTakeaway,
    setDine,
    setPaymentMode,
    setDeliveryTime,
    showModal,
} from "../../../actions"
import { baseurl, openTab, scrollToTop } from "../../Custom"

import OrderDetails from "./OrderDetails"
import LoadingBtn from "../../includes/btn/LoadingBtn"
import Modal from "../../includes/custom-modal/Modal"
// import ImageAbout from "./ImageAbout";
class Checkout extends Component {
    componentDidMount() {
        scrollToTop()
        this.props.fetchPickedupLocation()
        this.props.setDeliveryTime(this.minDeliveryTime())
    }

    gotoPayment = (service_custom_field_1, custom_field_1) => {
        let products = this.props.addedItems
        let grandTotal = this.props.total
        let contact_id = this.props.user
        let payment_mode = this.props.payment_mode
        let deliveryTime = this.props.delivery_time

        this.props.showModal()

        this.props.placeOrder(
            products,
            contact_id.id,
            grandTotal,
            service_custom_field_1,
            custom_field_1,
            payment_mode,
            deliveryTime
            // shipping_address
        )
        // console.log(service_custom_field_1);
    }

    changePaymentMode = (val) => {
        this.props.setPaymentMode(val)
    }

    deliveryTime = (val) => {
        console.log(val)
        this.props.setDeliveryTime(val)
    }

    handleInputChange = (evt) => {
        console.log(evt.target.value)
        this.props.setOrderNote(evt.target.value)
    }
    handleInputDine = (val) => {
        //console.log(evt.target.value);
        this.props.setDine(val)
    }
    handleInputTakeaway = (val) => {
        //console.log(evt.target.value);
        this.props.setTakeaway(val)
    }

    minDeliveryTime = () => {
        let date = new Date()

        let hour = date.getHours()
        let minute = date.getMinutes()

        let newDate = new Date(date.setHours(hour, minute + 20))
        return newDate
    }

    maxDeliveryTime = () => {
        //midnight
        let date = new Date()
        let newDate = new Date(date.setHours(23, 59, 59))
        return newDate
    }

    setCurrentTime = (date = new Date()) => {}

    handleInput = (evt) => {
        //console.log(evt.target.value);
        this.props.setDelivery(evt.target.value)
    }
    render() {
        let service_custom_field_1 = this.props.service_custom_field_1
        let custom_field_1 = this.props.custom_field_1
        let shipping_address = this.props.shipping_address
        let payment_mode = this.props.payment_mode

        const { isSubmitting, modalOpen } = this.props.commonData

        function show1() {
            document.getElementById("div1").style.display = "none"
        }
        function show2() {
            document.getElementById("div1").style.display = "block"
        }

        const { config } = this.props.cartdata
        const pinLocations =
            config &&
            config.pickup_addresses &&
            config.pickup_addresses.length > 0
                ? config.pickup_addresses.map((location) => {
                      return (
                          <div
                              key={location.id}
                              className="flex gap-4 border-radio-form"
                          >
                              <input
                                  type="radio"
                                  id={location.location_id}
                                  name="delivery"
                                  value={location.location_name}
                                  className="self-center "
                                  onChange={(event) => this.handleInput(event)}
                              />
                              <label for={location.location}>
                                  {location.address_line_1}
                              </label>
                          </div>
                      )
                  })
                : null
        const { success } = this.props.success
        if (success == true) {
            window.location.href = `${baseurl}my-orders`
        }

        return (
            <div className=" bg-white  menu-container ">
                {/* <Formik
          initialValues={{ service_custom_field_1: "" }}
          onSubmit={(values, actions) => {
            this.placeOrder(values, actions);
          }}
          enableReinitialize={true}
        >
          {(props: FormikProps<any>) => (
            <Form> */}
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-6 p-6 md:pr-20 md:pl-20 pt-0">
                    <div className="col-span-2  ">
                        {/* <h1 className="mail text-xl">Billing details</h1> */}
                        <div>
                            <div className="mt-8">
                                <h1 className="mb-2 text-gray-600">
                                    Delivery Type
                                </h1>

                                <div className="flex gap-2 md:gap-32">
                                    {/* location.orders == "closed" ? null : location.orders ==
              "open" ? */}
                                    {config.delivery == "open" ? (
                                        <div className="flex gap-4">
                                            <input
                                                type="radio"
                                                name="deliverytype"
                                                value="delivery"
                                                className="self-center "
                                                onClick={() => show2()}
                                            />

                                            <label
                                                for="delivery"
                                                className="self-center text-sm font-semibold"
                                            >
                                                Delivery
                                            </label>
                                        </div>
                                    ) : config.delivery == "closed" ? (
                                        " "
                                    ) : null}

                                    <div className="flex gap-4 ">
                                        <input
                                            type="radio"
                                            name="custom_field_1"
                                            value="Takeaway"
                                            className="self-center "
                                            //  onClick={() => show1()}
                                            checked={
                                                custom_field_1 === "Takeaway"
                                                    ? true
                                                    : false
                                            }
                                            onChange={() =>
                                                this.handleInputTakeaway(
                                                    "Takeaway"
                                                )
                                            }
                                        />

                                        <label
                                            for="takeaway"
                                            className="self-center text-sm font-semibold"
                                        >
                                            Take Away
                                        </label>
                                    </div>
                                    <div className="flex gap-4 ">
                                        <input
                                            type="radio"
                                            name="custom_field_1"
                                            value="Dine"
                                            className="self-center "
                                            checked={
                                                custom_field_1 === "Dine"
                                                    ? true
                                                    : false
                                            }
                                            // onClick={() => show1()}
                                            onChange={() =>
                                                this.handleInputDine("Dine")
                                            }
                                        />

                                        <label
                                            for="dine"
                                            className="self-center text-sm font-semibold"
                                        >
                                            Dine
                                        </label>
                                    </div>
                                </div>
                                <div id="div1" class="hide">
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-2 gap-4">
                                        {pinLocations}
                                    </div>
                                </div>

                                {/* <input
                        type="text"
                        placeholder="Message On Cake"
                        className=" quantity-select w-full rounded focus:outline-none text-sm"
                        maxlength="25"
                        name="message"
                        onChange={(event) =>
                          this.handleInputChange(event, props, "message")
                        }
                      /> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className="mail text-xl   mt-6 md:mt-0">
                            Additional information
                        </h1>
                        <h1 className="mt-6 text-gray-600 text-sm pb-2">
                            Order notes (optional)
                        </h1>
                        <textarea
                            className="bg-gray-200 border-2 w-full h-40 text-gray-700 text-xs p-2 md:p-6 focus:outline-none"
                            name="service_custom_field_1"
                            placeholder="Notes about your order ,e.g special notes for delivery"
                            type="text"
                            // value={service_custom_field_1}
                            onChange={(event) => this.handleInputChange(event)}
                        ></textarea>
                    </div>
                </div>
                {/* <button
          onClick={() => this.gotoPayment(service_custom_field_1,custom_field_1)}
          className="place-order-btn mt-8 font-bold text-xs focus:outline-none"
        >
          Place Order
        </button> */}
                <div className="grid    grid-cols-1  lg:grid-cols-3 gap-6 pl-2 pr-2 md:pl-20  md:pr-20">
                    <OrderDetails />

                    <div className=" col-span-0 lg:col-span-2 ">
                        <div className="cart-item-right-container">
                            <div className="cart-item-right-side">
                                <h1 className="">Payment Method </h1>
                                {/* <div className="flex gap-4 mt-2">
                  <input
                    type="radio"
                    name="payment"
                    value="cash"
                    className="self-center "
                    checked={payment_mode === 'cash' ? true : false}
                    onChange = {(event) => this.changePaymentMode('cash')}
                  />

                  <label
                    for="Cod"
                    className="self-center text-sm font-semibold"
                  >
                    Cash
                  </label>
                </div> */}
                                <div className="flex gap-4 mt-2">
                                    <input
                                        type="radio"
                                        name="payment"
                                        value="online"
                                        className="self-center "
                                        checked={
                                            payment_mode === "online"
                                                ? true
                                                : false
                                        }
                                        onChange={(event) =>
                                            this.changePaymentMode("online")
                                        }
                                    />

                                    <label
                                        for="Online"
                                        className="self-center text-sm font-semibold"
                                    >
                                        Online
                                    </label>
                                </div>
                                <div className="mt-4">
                                    <h1 className="mb-2">Select Time </h1>
                                    <div>
                                        <DatePicker
                                            selected={
                                                this.props.delivery_time
                                                    ? this.props.delivery_time
                                                    : null
                                            }
                                            onChange={(date) =>
                                                this.deliveryTime(date)
                                            }
                                            showTimeSelect
                                            showTimeSelectOnly
                                            minTime={this.minDeliveryTime()}
                                            maxTime={this.maxDeliveryTime()}
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="Select delivery time"
                                        />
                                    </div>
                                </div>

                                <div className="line mt-6 mb-6 "></div>
                                <div className="text-sm font-semibold">
                                    Your personal data will be used to process
                                    your order, support your experience
                                    throughout this website, and for other
                                    purposes described in our
                                    <span className="mail underline">
                                        privacy policy.
                                    </span>
                                </div>
                                {isSubmitting ? (
                                    <button className="place-order-btn mt-8 font-bold text-xs focus:outline-none cursor-not-allowed">
                                        <LoadingBtn />
                                    </button>
                                ) : (
                                    <button
                                        onClick={() =>
                                            this.gotoPayment(
                                                service_custom_field_1,
                                                custom_field_1,
                                                shipping_address
                                            )
                                        }
                                        className="place-order-btn mt-8 font-bold text-xs focus:outline-none"
                                    >
                                        Place Order
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {modalOpen && (
                    <Modal>
                        <div id="payment-form"></div>
                    </Modal>
                )}

                {/* </Form>
          )}
        </Formik> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cartdata: state.homepageData,
        success: state.homepageData.success,
        user: state.registerData.user,
        addedItems: state.cartData.addedItems,
        total: state.cartData.total,
        custom_field_1: state.homepageData.custom_field_1,
        payment_mode: state.homepageData.payment_mode,
        delivery_time: state.homepageData.delivery_time,
        service_custom_field_1: state.homepageData.service_custom_field_1,
        shipping_address: state.homepageData.shipping_address,
        commonData: state.commonFunctions,
    }
}
export default connect(mapStateToProps, {
    fetchPickedupLocation,
    placeOrder,
    setOrderNote,
    setDelivery,
    setTakeaway,
    setDine,
    setPaymentMode,
    setDeliveryTime,
    showModal,
})(Checkout)

/* <button
                  onClick={() => this.gotoPayment()}
                  className="place-order-btn mt-8 font-bold text-xs focus:outline-none"
                >
                  Place Order
                </button> */

/* <textarea
            placeholder="Notes about your order ,e.g special notes for delivery"
            className="bg-gray-200 border-2 w-full h-40 text-gray-700 text-xs p-2 md:p-6 focus:outline-none"
            name="service_custom_field_1"
            value={service_custom_field_1}
            onChange={(event) =>
              this.handleInputChange(event, props, "message")
            }
          ></textarea> */

/* </Field> */

/* <div className="flex gap-4 mt-4 mb-6">
                  <input
                    type="radio"
                    name="payment"
                    value="Online"
                    className="self-center "
                     
                  />

                  <label
                    for="online"
                    className="self-center text-sm font-semibold"
                  >
                    Online Transaction
                  </label>
                </div>
                <div className="p-4 text-xs bg-gray-800 mt-2">
                  Make your payment directly into our bank account. Please use
                  your Order ID as the payment reference. Your order will not be
                  shipped until the funds have cleared in our account.
                </div> */
