import React, { Component } from "react";
import { connect } from "react-redux";
import { baseurl } from "../../../Custom";
import { logout } from "../../../../actions";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    const { login, loginLoading } = this.props.user;

    if (!login && !loginLoading) {
      window.location.href = `${baseurl}`;
    }

    return (
      <section className="personal-details-page py-16">
        <div className="container mx-auto px-8 text-center">
          <h3 className="text-xl font-bold">
            Please wait while we redirect you...
          </h3>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.registerData,
  };
};

export default connect(mapStateToProps, { logout })(Logout);
