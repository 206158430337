import React, { Component } from "react";
import { Dialog } from "@react-md/dialog";
import { Link } from "react-router-dom";
import { useToggle } from "@react-md/utils";
import LoginDetails from "./LoginDetails";

const AddToLoginMobile = () => {
  const [visible, enable, disable] = useToggle(false);

  return (
    <>
      <button
        // onClick={this.IncrementItem}
        onClick={enable}
        className=" text-gray-800  add-to-cart-menu-btn   plus-minus-bntn dialogboxbtnmobileview  focus:outline-none"
      >
        +
      </button>

      {/* <span
        onClick={enable}
        class="ripple focus:outline-none text-center w-full  dialogboxbtnmobileview"
      >
        Login
      </span> */}

      <Dialog
        id="simple-dialog"
        visible={visible}
        aria-labelledby="dialog-title"
        type="full-page"
      >
        <span className="close3" id="dialog-close" onClick={disable}>
          &times;
        </span>
        <LoginDetails />
      </Dialog>
    </>
  );
};

export default AddToLoginMobile;
