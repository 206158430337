import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { baseurl } from "../../Custom";
import Header from "../nav/Header";
import Slider from "react-slick";
import { LeftArrow, RightArrow } from "../../CustomArrows";
import ImageAbout from "./ImageAbout";
class OurStory extends Component {
  render() {
    var settings = {
      infinite: true,

      slidesToShow: 1,
      speed: 500,

      nextArrow: <LeftArrow />,
      prevArrow: <RightArrow />,

      responsive: [
        {
          breakpoint: 620,
          settings: {
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            dots: true,
            infinite: true,
          },
        },
      ],
    };
    return (
      <div className="bg-white  menu-container">
        <div className="grid grid-cols-1 md:grid-cols-6">
          <div className="flex col-span-3">
            <img
              src={`${baseurl}restabook/wave2.png`}
              // src="restabook/wave2.png"
              alt="logo"
              className="wave-img"
            />
            <div className="p-6 ">
              <h1 className="our-story-text font-bold">Our Story</h1>
              <h1 className="font-bold text-4xl   few-words-txt">
                Few words about us
              </h1>
              <h1 className="font-bold text-2xl pb-6">. . . . . .</h1>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium totam aperiam. Eaque ipsa quae
                ab illo inventore veritatis et quasi architecto beatae vitae
                dicta sunt. Ut enim ad minima veniam, quis nostrum
                exercitationem ullam corporis suscipit laboriosam, nisi ut
                aliquid ex ea commodi consequatur.
                <br />
                Quis autem vel eum iure reprehenderit qui in ea voluptate velit
                esse quam nihil molestiae consequatur.
              </p>
              <button className="text-white text-sm font-bold pl-10 pr-10 pt-4 pb-4 explore-btn   ">
                Explore Our Menu
              </button>
            </div>
            <img
              src={`${baseurl}restabook/cube.png`}
              // src="restabook/cube.png"
              alt="logo"
              className=" cube-image"
            />
          </div>

          <div className="col-span-3 about ml-2 md:ml-0 mr-2  md:mr-10 ">
            <Slider {...settings}>
              <ImageAbout image="restabook/4-3.jpg" />
              <ImageAbout image="restabook/2-5.jpg" />
              <ImageAbout image="restabook/1-4.jpg" />
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default OurStory;
