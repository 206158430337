import React, { Component } from "react"
import { connect } from "react-redux"
import { TiDeleteOutline, TiThSmall } from "react-icons/ti"

import {
    addToCart,
    placeOrder,
    employeePlaceOrder,
    storeSearchTerm,
    storeMenu,
} from "../../../actions"
import { baseurl } from "../../Custom"

class CartItems extends Component {
    handleRemove = (id) => {
        // this.props.removeToCart(id);
        let addedItems = this.props.added_items
        let total = this.props.total

        const menu = this.props.menumain
        let products = []

        const menu_products =
            menu &&
            menu.map((category) => {
                category.products.length > 0 &&
                    category.products.map((product) => {
                        products.push(product)
                    })
            })
        // let addedItem = products.find((item) => item.id == id);
        let grandTotal = 0
        let existed_item = addedItems.find((item) => id == item.id)
        // console.log(products);
        if (existed_item) {
            let index = addedItems.findIndex(
                (index_product) => index_product.id == existed_item.id
            )

            //   if (addedItems[index].quantity >= 1) {
            //     addedItems[index].quantity -= 1;
            //     grandTotal = total - parseInt(existed_item.max_price);
            //   }
            //   if (addedItems[index].quantity <= 0) {
            addedItems.splice(index, 1)
            //}
            grandTotal =
                total - parseInt(existed_item.max_price) * existed_item.quantity
            this.props.addToCart(addedItems, grandTotal)
        }
    }

    confirmOrder = () => {
        let products = this.props.added_items
        let grandTotal = this.props.total
        let contact_id = 1

        this.props.employeePlaceOrder(
            products,
            contact_id,
            grandTotal,

            "",
            ""
        )
    }

    render() {
        let { config } = this.props.cartdata
        let addedItems = this.props.added_items
        let total = this.props.total
        let totalquantity = 0
        const { isSubmitting } = this.props.commonData

        return (
            <div>
                {addedItems && addedItems.length > 0 ? (
                    <div className="px-4 mt-2">
                        <div>
                            <h3 className="text-center py-1 px-2 bg-gray-800 text-white mb-4">
                                Cart Items
                            </h3>
                        </div>
                        {addedItems.map((item) => (
                            <div
                                key={item.id}
                                class="flex mb-2 border-b-2 border-gray-300 pb-2"
                            >
                                {/* <div className="text-sm img-div-remove self-center">
                                        <img
                                            src={item.image_url}
                                            alt="logo"
                                            className="h-16 w-full"
                                        />
                                    </div> */}

                                <div className="flex-1 self-center ml-2">
                                    <div className="text-sm text-gray-800 font-semibold overflow-ellipsis">
                                        {item.product}
                                    </div>
                                    <div className="text-sm text-gray-800  font-semibold img-div-remove">
                                        Rs {Math.round(item.max_price)}
                                    </div>
                                    <div className="text-sm text-gray-800  font-semibold">
                                        Qty: {item.quantity}
                                    </div>
                                </div>
                                <div className="ml-2 text-sm text-gray-800 font-bold self-center">
                                    Rs {item.quantity * item.max_price}
                                </div>
                                <div
                                    onClick={() => {
                                        this.handleRemove(item.id)
                                    }}
                                    className="self-center ml-2 text-xl  cursor-pointer  text-red-500 "
                                    title="Remove"
                                >
                                    <TiDeleteOutline />
                                </div>
                            </div>
                        ))}
                        <div className="flex justify-end">
                            <div className="text-bold text-sm">
                                Grand Total:
                                <span className="font-bold">Rs.{total}</span>
                            </div>
                        </div>
                        <div>
                            {isSubmitting ? (
                                <button
                                    type="button"
                                    disabled
                                    className="bg-green-700 w-full px-4 py-2 mt-8 text-center-4 text-white cursor-not-allowed"
                                >
                                    Please wait...
                                </button>
                            ) : (
                                <button
                                    onClick={() => this.confirmOrder()}
                                    className="bg-green-700 w-full px-4 py-2 mt-8 text-center-4 text-white"
                                >
                                    Place Order
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="flex justify-center">
                            <img
                                src={`${baseurl}restabook/cooking.png`}
                                className="w-32 mb-4"
                                alt="cooking"
                            />
                        </div>
                        <h3 className="text-center text-lg font-bold">
                            Cart is empty...
                        </h3>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        employeeData: state.employeeData,
        menumain: state.homepageData.menu,
        added_items: state.cartData.addedItems,
        total: state.cartData.total,
        cartdata: state.homepageData,
        totalquantity: state.cartData.totalquantity,
        commonData: state.commonFunctions,
        full_menu: state.homepageData.full_menu,
    }
}

export default connect(mapStateToProps, {
    addToCart,
    placeOrder,
    employeePlaceOrder,
    storeSearchTerm,
    storeMenu,
})(CartItems)
