import React from "react"
import { Provider } from "react-redux"
import store from "./store/Store"

import { BrowserRouter, Switch, Route } from "react-router-dom"

// styles

import "./components/styles/tailwind.generated.css"
import "./components/styles/tailwind.css"
import "./components/styles/slick.css"
import "./components/styles/slick-theme.css"
import "./components/styles/carousel.css"
import "./components/homepage/nav/nav.css"
import "./components/restabook.css"
// import "./components/product-details/material_orange.css";

//  components

import Nav from "./components/homepage/nav/Nav"
import OpeningHours from "./components/homepage/nav/OpeningHours"
import Footer from "./components/homepage/footer/Footer"
import WhyPeople from "./components/homepage/nav/WhyPeople"
import Specialities from "./components/homepage/nav/Specialities"
import Upcoming from "./components/homepage/nav/Upcoming"
import CustomerReview from "./components/homepage/nav/CustomerReview"
import HeaderAbout from "./components/homepage/about/HeaderAbout"
import HappyCustomer from "./components/homepage/about/HappyCustomer"
import Chef from "./components/homepage/about/Chef"
import MenuHeader from "./components/homepage/menu/MenuHeader"
import HeaderCart from "./components/homepage/cart/HeaderCart"
import CheckoutHeader from "./components/homepage/checkout/CheckoutHeader"
import OrderDetails from "./components/homepage/checkout/OrderDetails"
import Blank from "./components/homepage/checkout/Blank"
import Blank2 from "./components/homepage/cart/Blank2"
import Contact from "./components/homepage/contact/Contact"
import Explore from "./components/homepage/nav/Explore"
import MenuMain from "./components/homepage/menu/MenuMain"
import OurStory from "./components/homepage/about/OurStory"
import ContactHeader from "./components/homepage/contact/ContactHeader"
import Cart from "./components/homepage/cart/Cart"
import Checkout from "./components/homepage/checkout/Checkout"
import BlogHeader from "./components/homepage/blog/BlogHeader"
import Blog from "./components/homepage/blog/Blog"
import Logout from "./components/homepage/nav/Register/Logout"
import MyOrder from "./components/homepage/cart/MyOrder"
import OrderList from "./components/homepage/cart/OrderList"
import OrderHeader from "./components/homepage/cart/OrderHeader"
import MenuMain2 from "./components/homepage/menu/MenuMain2"
import TermsHeader from "./components/homepage/terms-conditions/TermsHeader"
import PrivacyHeader from "./components/homepage/privacy-policy/PrivacyHeader"
import TermsConditions from "./components/homepage/terms-conditions/TermsConditions"
import PrivacyPolicy from "./components/homepage/privacy-policy/PrivacyPolicy"
import Login from "./components/employee/login/Login"
import Dashboard from "./components/employee/dashboard/Dashboard"
import DeliveryHeader from "./components/homepage/delivery-policy/DeliveryHeader"
import DeliveryPolicy from "./components/homepage/delivery-policy/DeliveryPolicy"
const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/">
                        <div className="bg-white">
                            <Nav />
                            <Explore />
                            <OpeningHours />
                            <WhyPeople />
                            {/* <Specialities /> */}
                            <Chef />
                            <Upcoming />
                        </div>
                        {/* <CustomerReview /> */}
                    </Route>
                    <Route path="/logout">
                        <Logout />
                    </Route>
                    <Route path="/my-orders">
                        <div className="bg-white">
                            <OrderHeader />
                            <OrderList />
                        </div>
                    </Route>
                    <Route exact path="/about">
                        <HeaderAbout />
                        <OurStory />
                        {/* <HappyCustomer /> */}
                        <Chef />
                    </Route>
                    <Route exact path="/menu">
                        <div className="bg-white">
                            <MenuHeader />
                            <MenuMain />
                            {/* <Blank /> */}
                        </div>
                    </Route>
                    <Route exact path="/blog">
                        <BlogHeader />
                        <Blog />
                        {/* <Blank /> */}
                    </Route>
                    <Route exact path="/cart">
                        <div className="bg-white">
                            <HeaderCart />
                            <Cart />
                            {/* <Blank2 /> */}
                        </div>
                    </Route>
                    <Route exact path="/checkout">
                        <div className="bg-white">
                            <CheckoutHeader />
                            <Checkout />
                        </div>
                        {/* <Blank /> */}
                    </Route>
                    {/* <Route exact path="/checkout">
            <CheckoutHeader />
            <MyOrder />
            
          </Route> */}
                    <Route exact path="/enquiry">
                        <div className="bg-white">
                            <ContactHeader />
                            <Contact />
                        </div>
                        {/* <Blank /> */}
                    </Route>

                    <Route exact path="/terms-conditions">
                        <div className="bg-white">
                            <TermsHeader />
                            <TermsConditions />
                        </div>
                        {/* <Blank /> */}
                    </Route>

                    <Route exact path="/privacy-policy">
                        <div className="bg-white">
                            <PrivacyHeader />
                            <PrivacyPolicy />
                        </div>
                        {/* <Blank /> */}
                    </Route>

                    <Route exact path="/delivery-policy">
                        <div className="bg-white">
                            <DeliveryHeader />
                            <DeliveryPolicy />
                        </div>
                        {/* <Blank /> */}
                    </Route>

                    <Route exact path="/employee/login">
                        <div className="bg-white">
                            <Login />
                        </div>
                        {/* <Blank /> */}
                    </Route>

                    <Route exact path="/employee/dashboard">
                        <div className="bg-white">
                            <Dashboard />
                        </div>
                        {/* <Blank /> */}
                    </Route>
                </Switch>
                <Footer />
            </BrowserRouter>
        </Provider>
    )
}
export default App
