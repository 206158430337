import React, { Component } from "react";
import { Dialog } from "@react-md/dialog";
import { Link } from "react-router-dom";
import { useToggle } from "@react-md/utils";

import { list } from "purgecss/node_modules/postcss";
import ImageAbout from "./ImageAbout";

const ImageSlider = ({ image, image1 }) => {
  const [visible, enable, disable] = useToggle(false);

  return (
    <>
      <img
        src={image}
        alt="logo"
        className=" w-full  shadow-lg gallery-homepage cursor-pointer"
        onClick={enable}
      />
      {/* <li
        onClick={enable}
        class="self-center font-bold text-xs text-right home-nav-item text-account"
      >
        LOGIN
      </li> */}

      <Dialog
        id="simple-dialog"
        visible={visible}
        aria-labelledby="dialog-title"
        className=" gallery-homepage2"
        onRequestClose={disable}
      >
        {/* <span className="close3" id="dialog-close" onClick={disable}>
          &times;
        </span> */}
        <ImageAbout />
      </Dialog>
    </>
  );
};

export default ImageSlider;
