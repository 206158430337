import React from "react";

import { baseurl, baseurl2 } from "../../Custom";
import { connect } from "react-redux";

class MyOrder extends React.Component {
  render() {
    const { item, price, quantity, image, quantity1, total } = this.props;
    return (
      <div
        className=" order-menuitems-container flex justify-between  mb-6"
        // key={product.id}
      >
        {/* <div className="menu-image-container col-span-2 md:col-span-1">
          <img src={image} alt="logo" className="order-items-image " />
        </div> */}

        <div className="p-2 md:p-6 menu-notes-menu-page self-center">
          <h1 className="text-xs md:text-xl font-semibold text-gray-800  order-product-name">
            {item}
          </h1>

          <div className="">
            <p className="mail self-center text-xs md:text-sm md:text-current">
              Rs {Math.round(price)} x {quantity1} = Rs {total}
            </p>
          </div>
        </div>
        <div className="p-6 menu-notes-menu-page  self-center">
          <h1 className=" text-xs md:text-sm font-semibold text-gray-800">
            Qty {quantity}
          </h1>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.registerData,
  };
};

export default connect(mapStateToProps, {})(MyOrder);
