import React, { Component } from 'react'
import { FiLogOut } from 'react-icons/fi'
import { connect } from 'react-redux'

import { employeeLogout } from '../../../actions'
import LoadingIcon from '../../includes/btn/LoadingIcon'
import { baseurl } from '../../Custom'

class Header extends Component {

    logOut = () => {
        this.props.employeeLogout()
    }

    render() {

        const { login, loginLoading } = this.props.employeeData
        const { isSubmitting } = this.props.commonData

        if(!login && !loginLoading){
            window.location.href = `${baseurl}employee/login`
        }

        return (
        <nav className='bg-white px-4'>
            <div className='container mx-auto px-2 py-2'>
                <div className='flex justify-between'>
                    <img src={`${baseurl}restabook/logo-cafe.png`} className=' w-24' alt="logo" />
                    <div className='self-center'>
                        <button type="button" onClick={() => this.logOut()} className='bg-red-500 hover:bg-red-700 text-white px-4 py-2 flex' disabled={ isSubmitting ? true : false }> <div className='mt-1 mr-2'><FiLogOut /></div> { isSubmitting ? <LoadingIcon /> : 'Logout' }</button>
                    </div>
                </div>
            </div>
        </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        employeeData: state.employeeData,
        commonData: state.commonFunctions,
    }
}

export default connect(mapStateToProps, { employeeLogout })(Header)