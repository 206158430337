import React from "react";

import { Link } from "react-router-dom";
import { baseurl, openTab } from "../../Custom";

import MyOrder from "./MyOrder";
import { myOrders, updateProfile } from "../../../actions";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "../contact/react-datepicker.css";
import { Form, Formik, Field } from "formik";
import ErrorMsg from "../../includes/inputs/ErrorMsg";
import * as Yup from "yup";
import LoadingBtn from "../../includes/btn/LoadingBtn";

import './order-list.css'

class OrderList extends React.Component {
  componentDidMount() {
    this.props.myOrders();
  }
  constructor() {
    super();
    this.state = {
      showMe: true,
    };
  }
  componentWillMount() {
    setTimeout(() => {
      this.setState({
        showMe: false, 
      });
    }, 2000);
  }
  myTextInput = ({ name, field, placeholder, type }) => {
    return (
      <div className="w-full">
        <div className="text-input">
          <input
            placeholder={placeholder}
            type={type}
            name={name}
            className={`appearance-none  md:mt-4 focus:outline-none border-2 text-sm bg-gray-200 w-full p-2    `}
            {...field}
          />
        </div>
      </div>
    );
  };

  toggleCollapse = (order) => {
      

      document.querySelector(`#block-${order}`).classList.toggle("active");
      let content = document.querySelector(`#info-${order}`); //
      if (content.style.display === "block") {
        content.style.display = "none";
      } else {
        content.style.display = "block";
      }

  }

  submitButton = (values, actions) => {
    this.props.updateProfile(values, actions);
    console.log(this.props.submitButton);
  };
  displayDate = (dob) => {
    let ndob = new Date(dob);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let month = months[ndob.getMonth()];
    let sdob = ndob.getDate();
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let day = days[ndob.getDay()];

    return `${day}, ${sdob} ${month}`;
  };

  formatDate = (dob) => {
    let year = dob.getFullYear();
    let month = dob.getMonth() + 1;
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let day = dob.getDate();
    //let monthName = months[date.getMonth()];

    return `${year}-${month}-${day}`;
  };
  render() {
    const menumain = this.props.orders;
    const totalorders =
      menumain && menumain.length > 0
        ? menumain.map((order_data) => {
            const products =
              order_data.sell_lines && order_data.sell_lines.length > 0
                ? order_data.sell_lines.map((product) => {
                    return (
                      <MyOrder
                        key={product.id}
                        item={
                          product && product.product
                            ? product.product.name
                            : null
                        }
                        price={product.unit_price}
                        total={product.quantity * product.unit_price}
                        quantity={product.quantity}
                        quantity1={product.quantity}
                        image={
                          product && product.product
                            ? product.product.image_url
                            : null
                        }
                      />
                    );
                  })
                : null;

            return (
              <>
                <div className=" menu-grid-menu-page grid grid-cols-1 lg:grid-cols-8 ml-2 mr-2 lg:ml-32 lg:mr-32 mb-2 mt-6 collapsible cursor-pointer" id={`block-${order_data.invoice_no_text}`} onClick={() => this.toggleCollapse(order_data.invoice_no_text)}>
                  {/* <div className="line-menu self-center"></div> */}
                  <div
                    key={order_data.id}
                    className="grid  grid-cols-1 md:grid-cols-4 menu-grid col-span-1 lg:col-span-7"
                    
                  >
                    <div className="pl-6 self-center text-sm">
                      Date: {order_data.transaction_date}
                    </div>

                    <div className="   pl-6 md:pl-0 col-span-2 md:text-center  self-center ">
                      <div
                        className={`   text-sm lg:text-xl text-white    self-center  `}
                      >
                        {/* Order Status:{" "}
                        {order_data.res_order_status == "received"
                          ? "Order Is Received"
                          : order_data.res_order_status == "cooking"
                          ? "Cooking"
                          : order_data.res_order_status == "ready"
                          ? "Order Is Ready"
                          : null} */}
                          Order Token : #{order_data.invoice_no_text}
                      </div>
                    </div>

                    <div className="self-center md:text-right pl-6 md:pl-0 pr-6 text-sm">
                      Total Amount: Rs {Math.round(order_data.final_total)}
                    </div>
                  </div>
                  {/* <div className="line-menu self-center"></div> */}
                </div>
                <div className="ml-2 mr-2 md:ml-16 md:mr-16 lg:ml-40 lg:mr-40 content hidden" id={`info-${order_data.invoice_no_text}`}>
                  {products}
                </div>
              </>
            );
          })
        : null;

    const update_profile = this.props.getform;
    let selected_date;
    const { isSubmitting } = this.props.commonData;
    return (
      <>
        <div className="  menu-container  rounded      pr-2 md:pl-10 md:pr-10  lg:pr-48 pl-2   lg:pl-48   grid grid-cols-2 md:grid-cols-2">
          <Link to="#">
            <div className="text-center tab11">
              <button
                type="button"
                id="tab-0"
                className="tablinks active md:m-0   focus:outline-none  font-bold text-sm   "
                onClick={() => openTab("orders", "tab-0")}
              >
                My Order List
              </button>
            </div>
          </Link>

          <Link to="#">
            <div className="text-center tab11">
              <button
                type="button"
                id="tab-1"
                className="tablinks md:m-0   focus:outline-none    font-bold text-sm     "
                onClick={() => openTab("profiles", "tab-1")}
              >
                Profile
              </button>
            </div>
          </Link>
        </div>
        <div id="orders" className="tabcontent   active   w-auto     ">
          <div className="latest-slide  relative left-0 z-0          ">
            <div className="        bg-white       ">
              {totalorders ? (
                totalorders
              ) : (
                <div className="text-center  mt-20">
                  <div class="loader m-auto"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          id="profiles"
          className="tabcontent    ml-3 mr-3 md:ml-20 md:mr-20  lg::ml-50 lg:mr-50       "
        >
          <div className="latest-slide  relative left-0 z-0   mt-10   ">
            <Formik
              initialValues={{
                full_name: "",
                

                dob: "",
                email: "",

                mobile: "",
              }}
              // validate={(values) => {
              //   const errors = {};

              //   if (!values.name) {
              //     errors.name = <div className="error-msg">Enter Name</div>;
              //   }

              //   if (!values.date) {
              //     errors.date = <div className="error-msg">Enter Date</div>;
              //   }
              //   if (!values.no_of_people) {
              //     errors.no_of_people = (
              //       <div className="error-msg">Enter No of People</div>
              //     );
              //   }
              //   if (!values.message) {
              //     errors.message = (
              //       <div className="error-msg">Enter Message Field</div>
              //     );
              //   }

              //   return errors;
              // }}

              onSubmit={(values, actions) => {
                this.submitButton(values, actions);
              }}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                full_name: Yup.string().required("Required Field"),
               
                dob: Yup.string().required("Required Field"),
              })}
            >
              {(props: FormikProps<any>) => {
                console.log(props);
                return (
                  <Form>
                    <input className="invisible-input" />

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div>
                        <Field
                          placeholder="Enter Name"
                          className="  focus:outline-none border-2 bg-gray-200 w-full p-2"
                          component={this.myTextInput}
                          name="full_name"
                          type="text"
                        />
                        {props.touched.full_name &&
                          props.errors.full_name && (
                            <ErrorMsg msg={props.errors.full_name} />
                          )}
                      </div>
                       

                      <div>
                        <DatePicker
                          className="  focus:outline-none border-2 bg-gray-200 w-full p-2 md:mt-4"
                          placeholderText="Select Date Of Birth"
                          selected={props.values.dob}
                          // dateFormatCalendar="YYYY"
                          startDate={new Date()}
                          // minDate={new Date()}
                          onChange={(dob) => {
                            props.setFieldValue("dob", dob);

                            selected_date = new Date(this.formatDate(dob));
                          }}
                          dateFormat="dd-MM-yyyy"
                        />
                        {props.touched.dob && props.errors.dob && (
                          <ErrorMsg msg={props.errors.dob} />
                        )}
                      </div>

                      <div>
                        <Field
                          type="email"
                          component={this.myTextInput}
                          placeholder="Email Address(optional)"
                          name="email"
                          className="  focus:outline-none border-2 bg-gray-200 w-full p-2"
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="place-order-btn mt-8 font-bold text-xs focus:outline-none
                    "
                    >
                      {isSubmitting ? <LoadingBtn /> : `Update Profile`}
                      {/* {update_profile ? "Message Send" : `Send Message`} */}
                    </button>
                    {/* <button
                      type="submit"
                      className="continue-login-btn mb-5  text-center focus:outline-none"
                    >
            {isSubmitting ? <LoadingBtn /> : `Send Message  &#8594`};
                    
                    </button> */}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.registerData.orders,
    getform: state.profileformData.updateprofile,
    commonData: state.commonFunctions,
    selectedDate: state.enquiryformData.selected_date,
  };
};

export default connect(mapStateToProps, { myOrders, updateProfile })(OrderList);
